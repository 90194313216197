import React, { useState } from "react";
import * as Common from "../_common";
import { FormElement } from "../form_element";

const CustomFormTemplate = (props) => {
  const {
    htmlId,
    register,
    validate = null,
    error,
    hideValidationMessage = false,
    defaultValue = "",
    formElement,
    control,
    isRepeater,
    iterElement
  } = props;
  const maxSize = formElement.size;
  const repeaterForms = formElement.formElements;

  const [formSize, setFormSize] = useState(1);

  return (
    <Common.FormElementWrapper
      htmlId={htmlId}
      formElement={formElement}
      hideValidationMessage={hideValidationMessage}
      error={error}
      disableLabel={iterElement}
      iterElement={iterElement}
    >
        {Array.from(Array(formSize).keys()).map((index) => (
          <>
          {repeaterForms.map((element) => {
              return (
                <FormElement
                  {...props}
                  key={element.id}
                  formElement={{
                    ...element,
                    name : isRepeater ? `repeater_${index}_${element.name}` : element.name,
                    value: isRepeater ? `repeater_${index}_${element.name}` : element.name,
                  }}
                  iterElement={true}
                />
              );
            })}
          </>
        ))}
    </Common.FormElementWrapper>
  );
};

export default CustomFormTemplate;
