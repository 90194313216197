import { DEFAULT_LOCALIZED, DEFAULT_PUBLIC_INFO, VALIDATION_PROPS } from './constants'

export const dataGroup = props => {
    const { validationTextsOverride, publicFileInfoOverride, localizedTextsOverride } = props

    //VALIDATION TEXT
    const validationTextsDefault = Object.fromEntries(new Map([
        [VALIDATION_PROPS.KEY.NOTEMPTY, VALIDATION_PROPS.VALUE.NOTEMPTY],
        [VALIDATION_PROPS.KEY.UPLOAD, VALIDATION_PROPS.VALUE.UPLOAD],
        [VALIDATION_PROPS.KEY.CUSTOMFORMDATE, VALIDATION_PROPS.VALUE.CUSTOMFORMDATE],
        [VALIDATION_PROPS.KEY.CUSTOMFORMTIME, VALIDATION_PROPS.VALUE.CUSTOMFORMTIME],
        [VALIDATION_PROPS.KEY.CUSTOMFORMRADIONOTEMPTY, VALIDATION_PROPS.VALUE.CUSTOMFORMRADIONOTEMPTY],
        [VALIDATION_PROPS.KEY.CHECKBOXREQUIRED, VALIDATION_PROPS.VALUE.CHECKBOXREQUIRED],
        [VALIDATION_PROPS.KEY.RATING, VALIDATION_PROPS.VALUE.RATING],
        [VALIDATION_PROPS.KEY.SIMPLECHOICE, VALIDATION_PROPS.VALUE.SIMPLECHOICE],
        [VALIDATION_PROPS.KEY.REGCHECK, VALIDATION_PROPS.VALUE.REGCHECK],
        [VALIDATION_PROPS.KEY.REGCHECKOPTIONAL, VALIDATION_PROPS.VALUE.REGCHECKOPTIONAL],
        [VALIDATION_PROPS.KEY.MOBILENUMBER, VALIDATION_PROPS.VALUE.MOBILENUMBER],
        [VALIDATION_PROPS.KEY.MOBILENUMBEROPTIONAL, VALIDATION_PROPS.VALUE.MOBILENUMBEROPTIONAL],
        [VALIDATION_PROPS.KEY.SIGNATURENOTEMPTY, VALIDATION_PROPS.VALUE.SIGNATURENOTEMPTY],
        [VALIDATION_PROPS.KEY.CUSTOMFORMGEOLOCATION, VALIDATION_PROPS.VALUE.CUSTOMFORMGEOLOCATION],
        [VALIDATION_PROPS.KEY.NUMERIC, VALIDATION_PROPS.VALUE.NUMERIC],
        [VALIDATION_PROPS.KEY.NUMERIC_OPTIONAL, VALIDATION_PROPS.VALUE.NUMERIC_OPTIONAL],
        [VALIDATION_PROPS.KEY.MAXLENGTH, VALIDATION_PROPS.VALUE.MAXLENGTH],
    ]))


    const validationTexts = {
        ...validationTextsDefault,
        ...validationTextsOverride
    }

    // LOCALIZED TEXTS
    const localizedTexts = {
        ...DEFAULT_LOCALIZED,
        ...localizedTextsOverride
    }

    // PUBLIC FILE INFO
    const publicFileInfo = {
        ...DEFAULT_PUBLIC_INFO.UPLOAD,
        ...publicFileInfoOverride
    }

    return {
        validationTexts, localizedTexts, publicFileInfo,
    }
}