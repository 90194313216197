import React from 'react'
import PropTypes from 'prop-types'
import * as Fragments from './fragments'
import { Label } from './label'
import { ValidationErrorMessage } from './validation_error_message'
import { isMobile } from 'react-device-detect'
import MaxLengthMessage from './max_length_message'

export const FormElementWrapper = ({ children, htmlId, formElement, hideValidationMessage = false, error, errorMessageOverride = '', iterElement = false, disableLabel = false }) => {
    const widthResponsive = iterElement ? '100%' : isMobile ? '100%' : '80%' 
    return <Fragments.StyledFormElementContainer width={widthResponsive}>
        <Fragments.StyledFormElement cssMargin={0}>
            {!disableLabel && <Label htmlId={htmlId} requiredType={formElement.requiredType} label={formElement.text} /> } 
            {children}
        </Fragments.StyledFormElement>
        <MaxLengthMessage maxLength={formElement.maxLength} type={formElement.type} name={formElement.name} />
        <ValidationErrorMessage hideValidationMessage={hideValidationMessage} error={error} errorMessageOverride={errorMessageOverride} />
    </Fragments.StyledFormElementContainer>
}

FormElementWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    htmlId: PropTypes.string.isRequired,
    formElement: PropTypes.object.isRequired,
    hideValidationMessage: PropTypes.bool,
    error: PropTypes.object,
    errorMessageOverride: PropTypes.string
}
