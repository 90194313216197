import React from 'react'
import { TYPE_RATING } from '../../constants'
import Radio from './radio'
import * as Common from '../_common'
import PropTypes from 'prop-types'

const RatingImage = (props) => {
    const { name, htmlId, register, validate } = props
    const typeRadio = [TYPE_RATING.POSITIV, TYPE_RATING.NEUTRAL, TYPE_RATING.NEGATIV]
    return <Common.Fragments.FlexRating id={htmlId}>
        {
            typeRadio.map((t, i) => {
                return <Radio name={name} register={register} typeRadio={t} key={i} validate={validate} />
            })
        }
    </Common.Fragments.FlexRating>
}

RatingImage.propTypes = {
    name: PropTypes.string,
    htmlId: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    validate: PropTypes.func
}

export default RatingImage
