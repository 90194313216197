import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

export const StyledTextInputWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;

    & input{
        color: ${props => props.theme.color.lightPowderGray};
        border: none;
        border-bottom: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.borderColorInput};
        height: 38px;
        padding-left: ${props => props.theme.inputPaddingLeft};
        padding-top: 0;
        border-radius: ${props => props.theme.formElementBorderRadius};
        padding-bottom: 0;
        width: 100%;
        box-sizing: border-box;

        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
            height: 41px;
            font-size: ${props => props.theme.fontSize.medium18};
        }

        &:hover {
            border-bottom: 1px solid ${props => props.disabled ? props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red : props.theme.activeBorderColor};
        }

        &:active {
            border-bottom: 1px solid ${props => props.theme.activeBorderColor};
        }

        &:disabled {
            background-color: ${props => props.theme.color.gray5};
            color: ${props => props.theme.color.anthracite50};
        }

        &:focus{
            outline: none;
            border-bottom: 1px solid ${props => props.theme.focusBorderColor};
        }

        &:invalid{
            border-bottom: 1px solid ${props => props.hasPattern ? props.theme.color.red : props.theme.color.gray15};
        }


    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
`

export const StyledFormElementContainer = styled.div`
    padding: 24px 30px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.formElementBackground};
    border-radius: ${props => props.theme.formElementBorderRadius};
    width: ${props => props.width};
`

export const StyledAgbDatenschutzContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    margin: 10px;
    margin-bottom: 20px;
    background-color: ${props => props.theme.formElementBackground};
    border-radius: ${props => props.theme.formElementBorderRadius};
    border: ${props => props.theme.formElementBorder};
`

export const StyledFormElement = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.cssWidth ? props.cssWidth : '100%'};
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};
    margin:  ${props => props.cssMargin ? props.cssMargin : '0'};
    background-color: ${props => props.theme.formElementBackground};

    & label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.labelFontSize};
    }
`
export const StyledAgbElement = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.cssWidth ? props.cssWidth : '100%'};
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};
    margin:  ${props => props.cssMargin ? props.cssMargin : '0'};

    & h4 {
        font-size: ${props => props.theme.fontSize.medium18};
        font-weight: ${props => props.theme.fontWeight.headLine3};
        margin-top: 0px;
        margin-bottom: 16px;
    }

    & label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.labelFontSize};

        & span {
            font-size: ${props => props.theme.labelFontSize};
            color: ${props => props.theme.color.policyTextColor};
            cursor: pointer;
        }
    }
`

export const StyledAgbPadding= styled.div`
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
`

export const StyledTextArea = styled.textarea`
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    color: ${props => props.theme.color.anthracite};
    border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.borderColorInput};
    box-shadow: none;
    margin-top: 4px;
    height: 180px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 9px;
    width: ${props => props.cssWidth};
    min-height: ${props => props.isFilled ? '120px' : props.cssMinHeight};
    resize: none;
    overflow: ${props => props.isFilled ? 'auto' : 'hidden'};

     ::placeholder {
        color: ${props => props.theme.color.anthracite50};
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.theme.color.anthracite50};
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.theme.color.anthracite50};
      }

    :hover {
        border: 1px solid ${props => props.disabled ? props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red : props.theme.color.lightPowderGray};
    }

    :active {
        border: 1px solid ${props => props.disabled ? props.theme.color.gray15 : props => props.theme.color.anthracite};
    }

    :disabled {
        background-color: ${props => props.theme.color.gray5};
        color: ${props => props.theme.color.anthracite50};
        border: 1px solid ${props => props.theme.color.gray15};
    }

    :focus{
        outline: none;
    }


`

export const StyledTimeWrapper = styled.div`
    width: ${isMobile ? '75%' : '30%'};
    margin: 0;
    padding: 4px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.borderColorInput};
    &:hover {
        border-bottom: 1px solid ${props => props.disabled ? props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red : props.theme.activeBorderColor};
    }

    .react-time-picker {
        width: 100%
    }

    .react-time-picker__button:enabled:hover .react-time-picker__button__icon {
        stroke: #9a9eab;
    }

    .react-time-picker__button svg {
        display: none;
        color: #404040;
        width: 16;
        height: 16;
    }

    .react-time-picker__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        background-color: white;
        margin-left: 6px;
        padding: 2px 8px;
        width: 100%;
        font-size: ${props => props.theme.fontSize.standard};
        font-weight: ${props => props.theme.fontWeight.standardRegular};
        color: ${props => props.theme.color.anthracite};
        border: none;
        box-shadow: none;
        height: 38px;
        box-sizing: border-box;
    }
`

export const StyledSelectWrapper = styled.div`
     .css-yk16xz-control {
        padding: 2px 8px;
        width: 100%;
        font-size: ${props => props.theme.fontSize.standard};
        font-weight: ${props => props.theme.fontWeight.standardRegular};
        color: ${props => props.theme.color.anthracite};
        border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
        box-shadow: none;
        margin-top: 4px;
        height: 38px;
        border-radius: 5px;
        box-sizing: border-box;
     }

     .css-1okebmr-indicatorSeparator {
        background-color: #404040;
     }

     .css-tlfecz-indicatorContainer {
         color: #404040;
         cursor: pointer;
     }
`

export const StyledHeadline = styled.h1`
    font-size: ${props => props.theme.fontSizeHeadline};
    font-weight: ${props => props.theme.fontWeightHeadline};
    color: #4D4D4D;
    width: 100%;
    text-align: left;
`

export const StyledDisplayDescription = styled.h2`
    font-size: ${props => props.theme.fontSizeDisplay};
    font-weight: ${props => props.theme.fontWeightDisplay};
    color: #4D4D4D;
    text-align: justify;
    word-break: break-word;
	white-space: pre-line;
    line-height: ${props => props.theme.lineHeightDisplay};
`

export const FlexRating = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100% ;
    flex: 1 1 100%;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: auto ;
    max-width: 100%;
    @media (max-width: ${props => props.theme.breakpoint.xSmall}px) {
        width: 100%;
    }
    overflow: unset;
`


export const CheckboxContainer = styled.div`
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &:hover input ~ .checkmark {
        background-color: #ccc;
    }

    input:checked ~ .checkmark {
        background-color: #ccc;
    }

    input:checked ~ .checkmark:after {
        display: none;
    }

    .checkmark:after {
        display: none;
        left: 9px;
        top: 5px;
        width:  5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`

export const Checkmark = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    border-radius: 5px;
    left: 0;
    width: ${p => p.width ? p.width : '22px'};
    height: ${p => p.height ? p.height : '22px'};
    background: #DDDDDD;

    i {
        font-size: ${p => p.width ? `calc(${p.width} - 6px)` : '16px'} !important;
        color: #f5f5f5
    }
    &:after {
        content: "";
        position: absolute;
        display: none;
    }
`
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })``

export const CheckboxWrapper = styled.div`
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    width: ${p => p.width};
    margin: 8px 0px;
`

export const CheckboxContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    margin: 4px 0px;
    width: 100%;
`

export const CheckboxContent = styled.div`
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    overflow-wrap: break-word;
    width: ${props => props.width !== undefined ? props.width : 'calc(100% - 50px)'};
`
