import styled from 'styled-components'
import { getRule } from './helpers'

export const FlexGrid = styled.div`
    display: flex;
    flex-direction: ${getRule('direction', 'row')};
    flex-wrap: ${props => props.noWrap !== undefined ? 'nowrap' : 'wrap'};
    width: ${props => props.width !== undefined ? props.width : '100%'} ;
    flex: 1 1 100%;
    justify-content: ${getRule('justify', '')};
    align-items: ${getRule('items', '')};
    align-content: ${getRule('content', '')};
    height:${props => props.height !== undefined ? props.height : 'auto'} ;
   max-width: ${props => props.maxWidth !== undefined ? props.maxWidth : '100%'};
    @media (max-width: ${props => props.theme.breakpoint.xSmall}px) {
        width: ${props => props.widthMobile !== undefined ? props.widthMobile : '100%'};
    }
    overflow: ${props => props.overflow !== undefined ? props.overflow : 'unset'};
`

export const FlexGridItem = styled.div`
    overflow: ${props => props.overflow !== undefined ? props.overflow : 'unset'};
    flex: ${props => props.grow !== undefined ? props.grow : 1} ${props => props.shrink !== undefined ? props.shrink : 1} ${props => props.basis !== undefined ? props.basis : 'auto'};
    max-width: ${props => props.maxWidth !== undefined ? props.maxWidth : 'unset'};
    width: ${props => props.width !== undefined ? props.width : 'auto'} ;
    display: ${props => props.hide !== undefined && props.hide ? 'none' : 'flex'};
    height:${props => props.height !== undefined ? props.height : 'auto'} ;
    justify-content: ${getRule('justify', 'flex-start')};
    text-align: ${getRule('text', '')};
    
    align-items: ${getRule('items', 'flex-start')};
    align-content: ${getRule('content', 'stretch')};
    margin-right: ${props => props.needSpaceRight !== undefined ? props.needSpaceRight : '0px'};
    margin-left: ${props => props.needSpaceLeft !== undefined ? props.needSpaceLeft : '0px'};
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        display: ${props => props.hideTablet !== undefined && props.hideTablet ? 'none' : 'flex'};
        width: ${props => props.widthMobile !== undefined ? props.widthMobile : '100%'};
        margin-bottom: ${props => props.marginBottom === undefined ? '8px' : props.marginBottom};;
        margin-right: ${props => props.needSpace ? '0px' : '0px'};
        margin-left: ${props => props.needSpaceLeft ? '0px' : '0px'};
        text-align: ${getRule('text', '')};
    }
    max-width: 100%;
    max-height: 100%;
`

/**
 * RULES
 * 
 * directionRowReverse
 * directionColumn
 * directionColumnReverse
 * 
 * 
 * itemsFlexStart
 * itemsFlexEnd
 * itemsCenter
 * itemsBaseline
 * 
 * 
 * contentFlexStart
 * contentFlexEnd
 * contentCenter
 * contentSpaceBetween
 * contentSpaceAround
 * 
 * 
 * justifySpaceBetween
 * justifySpaceAround
 * justifyFlexEnd
 * justifyCenter
 */

export const Filler = styled.div`
flex-grow: 1;
`
export const HDivider = styled.div`
border-bottom: 1px solid #d9d9d9;
height: 0px;
`

export const VDivider = styled.div`
border-left: 1px solid #d9d9d9;
margin-left: 16px;
padding-right: 16px;
width: 0px;
`

export const PushLeft10 = styled.div`
    margin-right: 10px;
`

export const PushLeft15 = styled.div`
    margin-right: 15px;
`

export const PushLeft16 = styled.div`
    margin-right: 16px;
`

export const PushLeft17 = styled.div`
    margin-right: 17px;
`

export const PushLeft18 = styled.div`
    margin-right: 18px;
`

export const PushLeft20 = styled.div`
    margin-right: 20px;
`

export const PushLeft30 = styled.div`
    margin-right: 30px;
`

export const PushLeft40 = styled.div`
    margin-right: 40px;
`

export const PushBottom10 = styled.div`
    margin-bottom: 10px;
`

export const PushBottom20 = styled.div`
    margin-bottom: 20px;
`

export const PushBottom30 = styled.div`
    margin-bottom: 30px;
`

export const PushBottom40 = styled.div`
    margin-bottom: 40px;
`

export const PushBottom50 = styled.div`
    margin-bottom: 50px;
`

export const PushBottom60 = styled.div`
    margin-bottom: 60px;
`

export const PushBottom70 = styled.div`
    margin-bottom: 70px;
`