import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Common from '../_common'
import { Controller } from 'react-hook-form'
import * as Fragments from './fragments'
import calendarIcon from './images/calender-icon.png'
import moment from 'moment'
import DatePicker from 'react-date-picker'
import { useState } from 'react'


const DateComponent = (props) => {
    const stripTimeZone = val => moment(val.setHours(0,0,0,0)).utcOffset(0, true).toDate()

    const { htmlId, validate = null, error, hideValidationMessage = false, control, formElement, defaultValue, setValue, iterElement } = props
    const formatedDefaultValue = defaultValue !== undefined && defaultValue !== null && defaultValue !== '' 
        ? new Date(defaultValue).toISOString().split('T')[0] 
        : ''
    useEffect(()=>{
        setValue(formElement.name, formatedDefaultValue)
    }, [])

    const [isOpen, setIsOpen] = useState(false);
    
    
    return <Common.FormElementWrapper
        htmlId={htmlId}
        formElement={formElement}
        hideValidationMessage={hideValidationMessage}
        error={error}
        iterElement={iterElement}
    >
        <Controller
            name={formElement.name}
            control={control}
            rules={{ validate }}
            render={({ field: { onChange, value } }) => (
                <Fragments.DateContainerCustom>
                    <span
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 12,
                            width: '20px',
                            height: '20px',
                            background: `url(${calendarIcon}) no-repeat center`,
                            backgroundSize: '20px',
                        }}
                        onClick={() => {
                            setIsOpen(true)
                        }}
                    >
                        {/* <Fragments.InputDateCustom
                            id={htmlId} 
                            type='date' 
                            onChange={onChange}
                            value={value}
                        /> */}
                        <Fragments.InputDateExample>

                            <DatePicker id={htmlId} onChange={(e) => {
                                onChange(stripTimeZone(e))
                            }} value={value} isOpen={isOpen} locale={'de-DE'} onCalendarClose={() => {
                                setIsOpen(false)
                            }}/>
                        </Fragments.InputDateExample>
                    </span>
                   {/* {value ? (
                    value
                   ) : ('YYYY-MM-DD')} */}
                    {value ? (
                        <div
                            style={{
                                color: '#000000',
                                fontSize: '14px',
                            }}
                            onClick={() => {
                                setIsOpen(true)
                            }}
                        >{moment(value).format('DD.MM.YYYY')}</div>
                    ) : (
                        <div
                            style={{
                                color: '#9a9eab',
                                fontSize: '14px',
                            }}
                            onClick={() => {
                                setIsOpen(true)
                            }}
                        >
                            tt.mm.jjjj
                        </div>
                    )}
                </Fragments.DateContainerCustom>
            )}
        />
    </Common.FormElementWrapper>
}
DateComponent.propTypes = {
    htmlId: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired
}

export default DateComponent