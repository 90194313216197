import React from 'react'
import PropTypes from 'prop-types'
import * as Fragments from './fragments'
import { TYPE_RATING } from '../../constants'
import positiv from './images/excellent.png'
import neutral from './images/average.png'
import negativ from './images/poor.png'

const mapImage = (type) => {
    switch (type) {
    case TYPE_RATING.POSITIV: return positiv
    case TYPE_RATING.NEUTRAL: return neutral
    case TYPE_RATING.NEGATIV: return negativ
    default: return positiv
    }
}

const mapCaptions = (type) => {
    switch (type) {
    case TYPE_RATING.POSITIV: return 'Excellent'
    case TYPE_RATING.NEUTRAL: return 'Average'
    case TYPE_RATING.NEGATIV: return 'Poor'
    default: return 'Excellent'
    }
}

const Radio = (props) => {
    const { typeRadio = 'positiv', checked, name, register, validate } = props
    return <Fragments.RadioWrapper large={{
        width: '60%',
        height: 'auto',
    }} typeRadio={typeRadio} className={`ec-dynamic-form-rating-radio-${typeRadio}`}>
        <label>
            <input type="radio" name={name} value={typeRadio} checked={checked} {...register(name, {
                validate
            })} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #d9d9d9',
                    width: '90%',
                    borderRadius: '15px',
                    padding: '32px 0',
                    gap: '24px',
                    marginLeft: '8px',
                    cursor: 'pointer',
                }}
            >
                <img style={{
                    width: '60%',
                    height: 'auto',
                }} src={mapImage(typeRadio)} alt={`description radio for ${typeRadio}`} className={`ec-dynamic-form-rating-radio-image-wrapper-${typeRadio}`} />
                <span style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}>{mapCaptions(typeRadio)}</span>
            </div>
        </label>

    </Fragments.RadioWrapper>
}

Radio.propTypes = {
    typeRadio: PropTypes.string,
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    register: PropTypes.func.isRequired,
    validate: PropTypes.func
}

export default Radio
