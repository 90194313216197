import React from 'react'
import { useRef } from 'react'
import { Controller } from 'react-hook-form'
import SignaturePad from 'react-signature-canvas-react17-compatible'
import { ButtonContainer } from './fragments'
import * as Common from '../_common'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSignatureWrapper = styled.div`
    margin-top: 10px;
	& .sigCanvas {
		touch-action: none;
		border: 1px solid ${(props) => props.theme.borderColorInput};
		border-radius: 5px;
		margin-bottom: 10px;
        width: 100%;
        height: 120px;
	}
`

const Signature = (props) => {
    const { validate, htmlId, error, hideValidationMessage = false, formElement, control, setValue, iterElement } = props
    const sign = useRef({})
    const clear = (onChange) => {
        sign.current.clear()
        setValue(formElement.name, '')
        onChange('')
    }
    const save = (onChange) => {
        const dataUrl = sign.current.isEmpty() ? '' : sign.current.getTrimmedCanvas().toDataURL('image/png')
        setValue(formElement.name, dataUrl)
        onChange(dataUrl)
    }
    return <Common.FormElementWrapper
        htmlId={htmlId}
        formElement={formElement}
        hideValidationMessage={hideValidationMessage}
        error={error}
        iterElement={iterElement}
    >
        <Controller
            name={formElement.name}
            control={control}
            rules={{ validate }}
            render={({ field: { onChange, value } }) => {
                let isDeleteDisabled = value === '' && value === undefined
                let isSaveDisabled = value !== '' && value !== undefined
                return <StyledSignatureWrapper>
                    <SignaturePad
                        id={htmlId}
                        ref={sign}
                        canvasProps={{ className: 'sigCanvas' }}
                    />
                    <ButtonContainer saved={isSaveDisabled}>
                        <button disabled={isDeleteDisabled} className='delete-button-ec-dynamicForm-sign' onClick={() => clear(onChange)}>Löschen</button>
                        <button disabled={isSaveDisabled} className='save-button-ec-dynamicForm-sign' onClick={() => save(onChange)}>Speichern</button>
                    </ButtonContainer>
                </StyledSignatureWrapper>
            }}
        />

    </Common.FormElementWrapper>
}

Signature.propTypes = {
    htmlId: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired
}

export default Signature
