import React from 'react'
import PropTypes from 'prop-types'
import * as Common from '../_common'

export const InputRegex = (props) => {
    const { htmlId, register, validate = null, error, hideValidationMessage = false, defaultValue = '', formElement, iterElement } = props
    
    return <Common.FormElementWrapper
        htmlId={htmlId}
        formElement={formElement}
        hideValidationMessage={hideValidationMessage}
        error={error}
        iterElement={iterElement}
    >
        <Common.Fragments.StyledTextInputWrapper>
            <input id={htmlId} name={formElement.name} defaultValue={defaultValue} {...register(formElement.name, {
                validate: validate
            })} className='ec-dynamic-form-element ec-dynamic-form-element-type-input-regex' />
        </Common.Fragments.StyledTextInputWrapper>
    </Common.FormElementWrapper>
}

InputRegex.propTypes = {
    htmlId: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired
}

export default InputRegex