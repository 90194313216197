import React, { useEffect } from 'react'
import * as Common from '../_common'
import { MapContainer, Marker, Popup, useMapEvents, TileLayer, MapConsumer } from 'react-leaflet'
import * as Fragments from './fragments'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useMemo } from 'react'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
})

L.Marker.prototype.options.icon = DefaultIcon

const GeoLocationForm = (props) => {
    const { htmlId, validate = null, error, hideValidationMessage = false, control, formElement, setValue, position, grantedPosition, iterElement } = props

    const [positionValue, setPosition] = useState(position)

    useEffect(() => {
        grantedPosition && setValue(formElement.name, positionValue)
    }, [setValue, grantedPosition, formElement.name, positionValue])

    function DraggableMarker({ onChange }) {
        const map = useMapEvents({
            click() {
                map.locate()
            },
            locationfound(e) {
                setPosition(e.latlng)
                onChange(e.latlng)
                setValue(formElement.name, e.latlng)
                map.flyTo(e.latlng, map.getZoom(15))
            },
        })
        const markerRef = useRef(null)
        const eventHandlers = useMemo(
            () => ({
                dragend() {
                    const marker = markerRef.current
                    if (marker != null) {
                        setPosition(marker.getLatLng())
                        onChange(marker.getLatLng())
                        setValue(formElement.name, marker.getLatLng())
                    }
                },
            }),
            [],
        )

        return (
            <Marker
                draggable={true}
                eventHandlers={eventHandlers}
                position={positionValue}
                ref={markerRef}>
                <Popup minWidth={90}>
                    you are here
                </Popup>
            </Marker>
        )
    }

    // saarbrücken position
    const defaultPosition = [positionValue.lat, positionValue.lng]

    return <Common.FormElementWrapper
        htmlId={htmlId}
        formElement={formElement}
        hideValidationMessage={hideValidationMessage}
        error={error}
        iterElement={iterElement}
    >
        <Controller
            name={formElement.name}
            control={control}
            rules={{ validate }}
            render={({ field: { onChange } }) => (
                <Fragments.StyledMapLeaflet>
                    <MapContainer center={defaultPosition} zoom={15}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MapConsumer>
                            {(map) => {
                                map.flyTo(positionValue, map.getZoom(15))
                                return <DraggableMarker onChange={onChange} />
                            }}
                        </MapConsumer>
                    </MapContainer>
                </Fragments.StyledMapLeaflet>
            )}
        />
    </Common.FormElementWrapper>
}

GeoLocationForm.propTypes = {
    htmlId: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    position: PropTypes.object,
    grantedPosition: PropTypes.bool,
}


export default GeoLocationForm
