import React from 'react'
import { useDynamicFormContext } from '../../contexts/use_dynamic_form_context'
import { AgbDatenschutzWrapper } from '../_common/agb_datenschutz_wrapper'
import AgbDatenschutzMapper from './mapper'
import PropTypes from 'prop-types'

const AgbDatenschutzComponent = props => {

    const { htmlId, error, hideValidationMessage = false, formElement, localizedTexts } = props
    const { hasAgb, hasDatenschutz, openAgb, openDatenschutz } = useDynamicFormContext()
    
    return <>
    <AgbDatenschutzWrapper htmlId={htmlId} error={error} hideValidationMessage={hideValidationMessage} hasAgb={hasAgb} hasDatenschutz={hasDatenschutz} openAgb={openAgb} openDatenschutz={openDatenschutz} localizedTexts={localizedTexts}>
        <AgbDatenschutzMapper formElements={formElement.formElements} {...props} />
    </AgbDatenschutzWrapper>
    </>
}

export default AgbDatenschutzComponent

AgbDatenschutzComponent.propTypes = {
    htmlId: PropTypes.string.isRequired,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    formElement: PropTypes.object.isRequired
}
