import Popup from "./pop_up";
import React, { useState } from "react";

const ExternalLink = (props) => {
  const { formElement, localizedTexts } = props;

  return isURL(formElement.text)
    ? <Popup url={formElement.text} localizedTexts={localizedTexts} />
    : formElement.text;
};

function isURL(str) {
  // Regular expression pattern to match URLs
  const urlPattern =
    /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/;
  return urlPattern.test(str);
}

export default ExternalLink;
