const decamelize = (str, separator) => {
    separator = typeof separator === 'undefined' ? '_' : separator

    return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
        .toLowerCase()
}

export const getRule = (ruleName, defaultRule) => (props) => {
    const foundRule = Object.keys(props).find(key => key.startsWith(ruleName))
    if (!foundRule || !props[foundRule]) {
        return defaultRule
    }

    //destructing array spread operator
    const [, ...rule] = decamelize(foundRule, '-').split('-')

    return rule.join('-')
}