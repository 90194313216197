import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Common from '../_common'
import { Controller } from 'react-hook-form'
import { useDynamicFormContext } from '../../contexts/use_dynamic_form_context'

const Check = (props) => {
    const { validate, htmlId, formElement, control } = props
    const { setValue, watch } = useDynamicFormContext()
    const [checked, setChecked] = useState(watch[formElement.name])
    return <label>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <Controller
            name={formElement.name}
            control={control}
            rules={{ validate }}
            render={({ field: { onChange, value } }) => {
                return <Common.Fragments.CheckboxContainer>
                    <Common.Fragments.HiddenCheckbox checked={value} id={htmlId} name={formElement.name} defaultValue={value} onChange={() => {
                        onChange(!checked)
                        setChecked(!checked)
                        setValue(formElement.name, !checked)
                    }} className='ec-dynamic-form-element ec-dynamic-form-element-type-checkbox' />
                    <Common.Fragments.Checkmark checked={value} {...props} className='checkmark'>
                        <i className="material-icons">{value ? 'done' : ''}</i>
                    </Common.Fragments.Checkmark>
                </Common.Fragments.CheckboxContainer>
            }}
        />
    </label>
}

Check.propTypes = {
    checked: PropTypes.bool,
    htmlId: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    validate: PropTypes.func,
    formElement: PropTypes.object.isRequired,
}

export default Check
