/**
 * Processes multiple validation methods
 *
 * @param {function[]} methods list of validation methods that accept the current value as only parameter
 * @returns {(bool|string)} returns the error message of the first failed validation method or true if all methods pass
 */
export const validateMultiple = (methods = []) => {
    return (...args) => {
        for(const method of methods){
            if(method(...args) !== true){
                return method(...args)
            }
        }
        return true
    }
}