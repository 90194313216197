import React from 'react'
import PropTypes from 'prop-types'
import * as Common from '../_common'
import { Controller } from 'react-hook-form'
import TimePicker from 'react-time-picker'
import ClockIcon from './images/icon-left.png'

export const TimeComponent = (props) => {
    // var banchMarkTime = document.getElementsByClassName('react-time-picker__inputGroup__divider')

    // for (let index = 0; index < banchMarkTime.length; index++) {
    //     banchMarkTime[index].innerHTML = ':'

    //     console.log(banchMarkTime[index])
        
    // }

    // // banchMarkTime[0].innerHTML = ':'
    // console.log(banchMarkTime)

    const { htmlId, validate = null, error, hideValidationMessage = false, control, formElement, iterElement } = props
    return <Common.FormElementWrapper
        htmlId={htmlId}
        formElement={formElement}
        hideValidationMessage={hideValidationMessage}
        error={error}
        iterElement={iterElement}
    >
        <Controller
            name={formElement.name}
            control={control}
            rules={{ validate }}
            render={({ field: { onChange, value } }) => (
                <Common.Fragments.StyledTimeWrapper>
                    <img 
                        src={ClockIcon}
                        alt='clock'
                        style={{
                            width: '22px',
                            height: '22px',
                        }}
                    />
                    <TimePicker hourPlaceholder='00' minutePlaceholder='00' disableClock={true} value={value} format={'HH:mm'} name={formElement.name} onChange={onChange}
                        className='ec-dynamic-form-element ec-dynamic-form-element-type-input' />
                </Common.Fragments.StyledTimeWrapper>
            )}
        />
    </Common.FormElementWrapper>
}

TimeComponent.propTypes = {
    htmlId: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired
}

export default TimeComponent