import React, { useState, useRef } from 'react'
import * as Common from '../_common'
import { FormElement } from '../form_element'
import * as Fragments from './fragments'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import Preloader from '../../common/themed_preloader'
import { isMobile } from 'react-device-detect'


const Gutachter = (props) => {
    const {
        htmlId,
        setValue,
        register,
        validate,
        error,
        hideValidationMessage = false,
        defaultValue = '',
        formElement,
        control,
        gutachterOptions,
        gutachterIsLoading,
        gutachterGetData,
        setPostalCodeGutachter,
        iterElement,
    } = props

    const [isInitial, setIsInitial] = useState(true)
    const selectRef = useRef(null)


    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#404040' : '#d9d9d9',
            borderRadius: '5px',
            boxShadow: 'none',
            marginTop: '10px',
            '&:hover': {
                borderColor: '#9a9eab',
            },
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#e6e6e6' : '#FFFFFF',
            color: 'black',
            '&:hover': {
                background: '#e6e6e6',
            }
        })
    }

    return (
        <Common.FormElementWrapper
            htmlId={htmlId}
            formElement={formElement}
            hideValidationMessage={hideValidationMessage}
            error={error}
            iterElement={iterElement}
        >
            <Common.Fragments.StyledTextInputWrapper>
                <Fragments.PostalCodeInputWrapper isMobile={isMobile} >
                    <input
                        onChange={(e) => setPostalCodeGutachter(e.target.value)}
                        placeholder='Postleitzahl'
                        style={{ 
                            width: isMobile === false && '200px',
                            fontSize: '14px',
                        }}
                    />
                    <Fragments.PostalCodeInputButton 
                        isMobile={isMobile}  
                        type='button'
                        onClick={() => {
                            gutachterGetData()
                            if(selectRef.current != null) selectRef.current.clearValue()
                            setValue(formElement.name, null)
                            setIsInitial(false)
                        }} 
                    >
                        {gutachterIsLoading ? 'Laden' : 'Suche' }
                    </Fragments.PostalCodeInputButton>
                </Fragments.PostalCodeInputWrapper>
            </Common.Fragments.StyledTextInputWrapper>
            {!isInitial && !gutachterIsLoading && gutachterOptions.length == 0 &&
                <Fragments.PostalCodeError>
                    Bitte geben Sie eine gültige Postleitzahl ein
                </Fragments.PostalCodeError>
            }
            
            <Controller
                htmlId={formElement.id}
                name={formElement.name}
                control={control}
                rules={{ validate }}
                render={({ field: { onChange, value } }) => (
                    gutachterOptions.length != 0 && (
                        <Common.Fragments.StyledSelectWrapper>
                            <Common.Label htmlId={htmlId} label={'Gutachter'}/>
                            <Select 
                            id={htmlId} 
                            defaultValue={value} 
                            styles={customStyles} 
                            placeholder='Bitte auswählen' 
                            isClearable={true} 
                            options={gutachterOptions}
                            ref={selectRef}
                            onChange={(val) => {
                                setValue(formElement.name, val === null ? null : val.value)
                                onChange(val === null ? null : val.value)
                            }} 
                            isDisabled={gutachterOptions.length == 0 ? true : false}
                            className='ec-dynamic-form-element ec-dynamic-form-element-type-multiplechoice' />
                        </Common.Fragments.StyledSelectWrapper>
                    )
                )}
            />
            
            
        </Common.FormElementWrapper>
    )
}

export default Gutachter