import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import * as fileHelpers from '../../../common/file_helpers'
import * as Fragments from './fragments'
import PropTypes from 'prop-types'

const DragAndDropUploader = ({
    onFileAdded,
    children,
    disabled = false,
    onFilesAdded,
    filled = false,
    name,
    htmlId
}) => {
    const options = {
        disabled: disabled,
        onDrop: useCallback(acceptedFiles => {
            fileHelpers.readFiles(acceptedFiles, onFileAdded, onFilesAdded)
        }, [onFileAdded, onFilesAdded])
    }
    const { getRootProps, getInputProps/*, isDragActive*/ } = useDropzone({ ...options })
    const { onClick, ...rootProps } = getRootProps()
    return <Fragments.DropZoneContainer>
                <Fragments.DropZone filled={filled} disabled={disabled} {...rootProps}>
                    <input name={name} id={htmlId} {...getInputProps()} />
                    {children}
                </Fragments.DropZone>
    </Fragments.DropZoneContainer>
    
}

DragAndDropUploader.propTypes = {
    htmlId: PropTypes.string.isRequired,
    onFilesAdded: PropTypes.func,
    onFileAdded: PropTypes.func,
    disabled: PropTypes.bool,
    filled: PropTypes.bool,
    name: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

export default DragAndDropUploader