import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const RequiredFieldMarkerWrapper = styled.span`
    color: ${props => props.theme.validationColor};
    font-size: inherit;
`

export const RequiredFieldMarker = ({isRequired = false}) => {
    return isRequired
        ? <RequiredFieldMarkerWrapper className='ec-dynamic-form-required-field-marker'>*</RequiredFieldMarkerWrapper>
        : null
}

RequiredFieldMarker.propTypes = {
    isRequired: PropTypes.bool
}
