import { nanoid } from 'nanoid'

const agbDatenschutzObjectDefault = (formElements) => ({
    'Id': 99999,
    'FormElements': formElements,
    'Text': '',
    'Value': 'agbDatenschutz',
    'RequiredType': '',
    'Type': 'agbDatenschutz',
    'Position': 0,
    'IsVisible': true,
    'StyleSheet': null,
    'Style': null,
    'Size': 0,
    'MaxLength': 0,
    'MinLength': 0,
    'Name': 'agbDatenschutz',
    'Checked': false,
    'Matching': null,
    'Help': null,
    'AppliedValue': null,
    'RegEx': null,
    'ShowButtons': true,
    'ShowUploadField': true,
    'ShowValidationText': false,
    'IsReadOnly': false,
    'FormId': null,
    'HtmlTemplate': null,
    'RessourceKey': null,
    'IsRequired': false,
    'Account': 0,
    'FormKey': ''
})

const agbObject = (textAgb) => ({
    'Id': nanoid(),
    'FormElements': [],
    'Text': textAgb,
    'Value': 'checkbox_agb',
    'RequiredType': 'CHECKBOXREQUIRED',
    'Type': 'checkbox',
    'Position': 0,
    'IsVisible': true,
    'StyleSheet': null,
    'Style': null,
    'Size': 0,
    'MaxLength': 0,
    'MinLength': 0,
    'Name': 'checkbox_agb',
    'Checked': false,
    'Matching': null,
    'Help': null,
    'AppliedValue': null,
    'RegEx': null,
    'ShowButtons': true,
    'ShowUploadField': true,
    'ShowValidationText': false,
    'IsReadOnly': false,
    'FormId': null,
    'HtmlTemplate': null,
    'RessourceKey': null,
    'IsRequired': false,
    'Account': 0,
    'FormKey': ''
})

const datenschutzObject = (textAgb) => ({
    'Id': nanoid(),
    'FormElements': [],
    'Text': textAgb,
    'Value': 'checkbox_datenschutz',
    'RequiredType': 'CHECKBOXREQUIRED',
    'Type': 'checkbox',
    'Position': 0,
    'IsVisible': true,
    'StyleSheet': null,
    'Style': null,
    'Size': 0,
    'MaxLength': 0,
    'MinLength': 0,
    'Name': 'checkbox_datenschutz',
    'Checked': false,
    'Matching': null,
    'Help': null,
    'AppliedValue': null,
    'RegEx': null,
    'ShowButtons': true,
    'ShowUploadField': true,
    'ShowValidationText': false,
    'IsReadOnly': false,
    'FormId': null,
    'HtmlTemplate': null,
    'RessourceKey': null,
    'IsRequired': false,
    'Account': 0,
    'FormKey': ''
})

export const agbDatenschutzObject = (hasAgb, hasDatenschutz, textAgb, textDatenschutz) => {
    const agb = agbObject(textAgb)
    const datenschutz = datenschutzObject(textDatenschutz)
    const formElements = () => {
        let arr = []
        switch (true) {
        case hasAgb && hasDatenschutz:
            return [
                recursivelyLowercaseJSONKeys(agb),
                recursivelyLowercaseJSONKeys(datenschutz)
            ]
        case hasAgb && !hasDatenschutz:
            return [
                recursivelyLowercaseJSONKeys(agb),
            ]
        case !hasAgb && hasDatenschutz:
            return [
                recursivelyLowercaseJSONKeys(datenschutz)
            ]
        case !hasAgb && !hasDatenschutz:
            return arr
        default:
            return arr
        }
    }
    return recursivelyLowercaseJSONKeys(agbDatenschutzObjectDefault(formElements()))
}

const isArray = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Array]'
}
const recursivelyLowercaseJSONKeys = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj
    }
    if (isArray(obj)) {
        return obj.map(o => recursivelyLowercaseJSONKeys(o))
    }
    return Object.keys(obj).reduce((prev, curr) => {
        prev[curr[0].toLowerCase() + curr.slice(1)] = recursivelyLowercaseJSONKeys(obj[curr])
        return prev
    }, {})
}

export const customFormElements = (hasAgb, hasDatenschutz, defaultFormElements, agbDatenschutzElements) => {
    if (hasAgb || hasDatenschutz) {
        const temp = defaultFormElements
        return [...(temp.slice(0, temp.length - 1)), agbDatenschutzElements, temp[temp.length - 1]]
    } else {
        return defaultFormElements
    }
}