import React from 'react'
import PropTypes from 'prop-types'
import * as Common from '../_common'
import Select from 'react-select'
import { Controller } from 'react-hook-form'
import { withTheme } from 'styled-components'

const Simplechoice = (props) => {
    const { htmlId, setValue, validate = null, error, hideValidationMessage = false, control, formElement, theme, localizedTexts, iterElement } = props
    const yesNoOptions = [
        {
            value: 0,
            label: localizedTexts.formElementSimpleChoiceNoOption
        },
        {
            value: 1,
            label: localizedTexts.formElementSimpleChoiceYesOption
        }
    ]

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#404040' : '#d9d9d9',
            borderRadius: '5px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#9a9eab',
            }
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? theme.buttonBackground : '',
            '&:hover': {
                background: '#e6e6e6'
            }
        })
    }
    return <Common.FormElementWrapper
        htmlId={htmlId}
        formElement={formElement}
        hideValidationMessage={hideValidationMessage}
        error={error}
        iterElement={iterElement}
    >
        <Controller
            name={formElement.name}
            control={control}
            rules={{ validate }}
            render={({ field: { onChange, value } }) => (
                <Common.Fragments.StyledSelectWrapper>
                    <Select id={htmlId} defaultValue={value} styles={customStyles} placeholder='Bitte Wählen Sie aus' isClearable={true} options={yesNoOptions} onChange={(val) => {
                        setValue(formElement.name, val === null ? null : val.value)
                        onChange(val === null ? null : val.value)
                    }} className='ec-dynamic-form-element ec-dynamic-form-element-type-simplechoice' />
                </Common.Fragments.StyledSelectWrapper>
            )}
        />
    </Common.FormElementWrapper>
}

Simplechoice.propTypes = {
    htmlId: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired,
    theme: PropTypes.object,
    localizedTexts: PropTypes.object,
}


export default withTheme(Simplechoice)
