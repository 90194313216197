import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as Fragments from './fragments'
import { isMobile } from 'react-device-detect'

export const AgbDatenschutzWrapper = ({ children, htmlId, hasAgb, hasDatenschutz, openDatenschutz, openAgb, localizedTexts }) => {
    const widthResponsive = isMobile ? '100%' : '80%'
    return <Fragments.StyledAgbDatenschutzContainer width={widthResponsive}>
        <Fragments.StyledAgbElement cssMargin={0}>
            <Fragments.StyledAgbPadding>
                {localizedTexts.showStatementTitle && (
                    <h4>
                        {
                            hasDatenschutz && <Fragment>
                                Datenschutz
                            </Fragment> 
                        }{
                            hasDatenschutz && hasAgb && <Fragment>
                                {' '}und{' '}
                            </Fragment>
                        }
                        {
                            hasAgb && <Fragment>
                                AGB
                            </Fragment>
                        }
                    </h4>
                )}
            <label
                className='ec-dynamic-form-label-agb'
                htmlFor={htmlId}
            >
                Hier finden Sie unsere {localizedTexts.agbText === 'AGB' ? 'Regelungen zu' : ''} {
                    hasDatenschutz && <Fragment>
                        <span onClick={openDatenschutz}>
                            {localizedTexts.datenschutzText}
                        </span> {
                            hasAgb && <Fragment>
                                {localizedTexts.agbText === 'AGB' ? ' sowie die' : 'und'}
                            </Fragment>
                        } 
                    </Fragment> 
                }{
                    hasAgb && <Fragment>
                        <span onClick={openAgb}>
                            {' '}{localizedTexts.agbText}
                        </span>
                        .
                    </Fragment>
                }
            </label>
            </Fragments.StyledAgbPadding>
            {children}
        </Fragments.StyledAgbElement>
    </Fragments.StyledAgbDatenschutzContainer>
}

AgbDatenschutzWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    htmlId: PropTypes.string.isRequired,
    formElement: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    error: PropTypes.object,
    errorMessageOverride: PropTypes.string,
    hasAgb: PropTypes.bool,
    hasDatenschutz: PropTypes.bool,
    openAgb: PropTypes.func.isRequired,
    openDatenschutz: PropTypes.func.isRequired
}
