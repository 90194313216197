export const getFilesFromFormData = (payload) => {
    const filesPayload = []
    var getAllKeys = Object.keys(payload)

    getAllKeys.forEach(keyName => {
        if (keyName.indexOf('formelement') !== -1) {
            filesPayload.push(...payload[keyName])
        }
    })

    return filesPayload
}