import React from 'react'
import PropTypes from 'prop-types'
import { RequiredFieldMarker } from './required_field_marker'

export const Label = ({requiredType = '', label = '', htmlId}) => {
    const isRequired = requiredType !== null && requiredType !== '' && !requiredType.toLowerCase().includes('optional')
    return <label
        style={{
            fontWeight: 'bold',
        }}
        className='ec-dynamic-form-label'
        htmlFor={htmlId}
    >{label}<RequiredFieldMarker isRequired={isRequired} /></label>
}

Label.propTypes = {
    requiredType: PropTypes.string,
    label: PropTypes.string,
    htmlId: PropTypes.string.isRequired
}
