import { VALIDATION_PROPS, FORM_ELEMENT_TYPE } from '../../constants'

/**
 * Validation methods
 *
 * @param {string} value current value of the form element
 * @param {object} formElement formElement data as json
 */
export const validationMethods = {
    noValidation: () => {
        return true
    },
    notEmpty: (value, formElement, validationTexts) => {
        return (value !== '' && value !== undefined && value !== null) || validationTexts[VALIDATION_PROPS.KEY.NOTEMPTY]
    },
    notEmptyUpload: (value, formElement, validationTexts) => {
        return (value !== '' && value !== undefined && value !== null && value.length > 0) || validationTexts[VALIDATION_PROPS.KEY.UPLOAD]
    },
    checkEachFileSize: (value, formElement, validationTexts, publicFileInfo) => {
        const shouldCheck = value !== '' && value !== undefined && value !== null && value.length > 0
        const errorFound = shouldCheck && value.some(v => v.metaData.size / 1000 > publicFileInfo.maxUploadSizeInKB)
        const errorFileName = errorFound && value.find(v => v.metaData.size / 1000 > publicFileInfo.maxUploadSizeInKB).attachment.fileName
        return !errorFound || `Die Datei '${errorFileName}' ist zu groß; Dateien dürfen nicht größer als ${publicFileInfo.maxUploadSizeInKB / 1000} MB sein`
    },
    checkTotalFilesSize: (value, formElement, validationTexts, publicFileInfo) => {
        const shouldCheck = value !== '' && value !== undefined && value !== null && value.length > 0
        const totalUploadFileSize = shouldCheck ? value.reduce((accumulator, current) => accumulator + current.metaData.size, 0) : 0
        return totalUploadFileSize / 1000 < publicFileInfo.totalFileSizeLimitInKb || `Gesamtgröße der Datein ist zu hoch; Bitte laden Sie nicht mehr als ${publicFileInfo.totalFileSizeLimitInKb / 1000} MB hoch`
    },
    checkAllowedExtensionFile: (value, formElement, validationTexts, publicFileInfo) => {
        const shouldCheck = value !== '' && value !== undefined && value !== null && value.length > 0
        const errorFound = shouldCheck && value.some(v => publicFileInfo.defaultDisallowedUploadFormats.includes(v.attachment.fileExtension))
        const errorFileName = errorFound && value.find(v => publicFileInfo.defaultDisallowedUploadFormats.includes(v.attachment.fileExtension)).attachment.fileName
        return !errorFound ||  `'${errorFileName}' kann nicht hochgeladen werden, da die Datei eine nicht erlaubte Erweiterung aufweist`
    },
    notEmptySelect: (value, formElement, validationTexts) => {
        let validationText = {}
        validationText[FORM_ELEMENT_TYPE.RATING] = validationTexts[VALIDATION_PROPS.KEY.RATING]
        validationText[FORM_ELEMENT_TYPE.MULTIPLECHOICE] = validationTexts[VALIDATION_PROPS.KEY.CUSTOMFORMRADIONOTEMPTY]
        validationText[FORM_ELEMENT_TYPE.SIMPLECHOICE] = validationTexts[VALIDATION_PROPS.KEY.SIMPLECHOICE]
        return (value !== '' && value !== undefined && value !== null) || validationText[formElement.type]
    },
    notEmptyChecked: (value, formElement, validationTexts) => {
        return (value !== '' && value !== undefined && value !== null && value) || validationTexts[VALIDATION_PROPS.KEY.CHECKBOXREQUIRED]
    },
    mustBeNumeric: (value, formElement, validationTexts) => {
        return (value === '' || new RegExp(/^[0-9]*$/).test(value)) || validationTexts[VALIDATION_PROPS.KEY.NUMERIC]
    },
    notEmptyDate: (value, formElement, validationTexts) => {
        return (value !== '' && value !== undefined && value !== null) || validationTexts[VALIDATION_PROPS.KEY.CUSTOMFORMDATE]
    },
    notEmptyRating: (value, formElement, validationTexts) => {
        return (value !== '' && value !== undefined && value !== null) || validationTexts[VALIDATION_PROPS.KEY.RATING]
    },
    notEmptySignature: (value, formElement, validationTexts) => {
        return (value !== '' && value !== undefined && value !== null) || validationTexts[VALIDATION_PROPS.KEY.SIGNATURENOTEMPTY]
    },
    mustBeValidTime: (value, formElement, validationTexts) => {
        return (value !== '' && value !== undefined && value !== null) || validationTexts[VALIDATION_PROPS.KEY.CUSTOMFORMTIME]
    },
    mustBeValidLocation: (value, formElement, validationTexts) => {
        return (value !== '' && value !== undefined && value !== null) || validationTexts[VALIDATION_PROPS.KEY.CUSTOMFORMGEOLOCATION]
    },
    mustBeValidRegex: (value, formElement, validationTexts) => {
        return (value === '' || new RegExp(formElement.regEx).test(value)) || validationTexts[VALIDATION_PROPS.KEY.REGCHECKOPTIONAL]
    },
    maxLength: (value, formElement, validationTexts) => {
        return (value === '' || value.length <= formElement.maxLength) || validationTexts[VALIDATION_PROPS.KEY.MAXLENGTH] + ` (max ${formElement.maxLength})`
    },
    mustBeValidPhoneNumber: (value, formElement, validationTexts) => {
        return (value === '' || value === undefined || value === null || new RegExp(/^.{4,15}[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/).test(value)) || validationTexts[VALIDATION_PROPS.KEY.MOBILENUMBEROPTIONAL]
    }
}
