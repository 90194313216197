import React, { createContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_ELEMENT_TYPE } from '../constants'
import PropTypes from 'prop-types'
import FormSaveEffect from '../helpers/form_save/component'

export const DynamicFormContext = createContext()

export const DynamicFormProvider = props => {
    const { children, formElements, validationTexts, publicFileInfo, localizedTexts, openAgb, openDatenschutz, hasAgb, hasDatenschutz, rsInsurancesOptions, gutachterOptions, gutachterGetData, setPostalCodeGutachter, gutachterIsLoading } = props
    const defaultValues = formElements.reduce((o, key) => {
        switch (key.type) {
        case FORM_ELEMENT_TYPE.CHECKBOX:
            return { ...o, [key.name]: false }
        case FORM_ELEMENT_TYPE.UPLOADER:
            return { ...o, ['formelement_' + key.id]: [] }
        default:
            return { ...o, [key.name]: '' }
        }
    }, {})
    const [stateForm, setStateForm] = useState(defaultValues)


    const { register, formState: { errors }, handleSubmit, getValues, setValue, control, watch } = useForm({ defaultValues })
    const setForm = () => {
        setStateForm({
            ...stateForm,
            ...getValues()
        })
    }
    const values = {
        register,
        errors,
        handleSubmit,
        getValues,
        setValue,
        control,
        watch,
        stateForm,
        setStateForm,
        setForm,
        validationTexts,
        publicFileInfo,
        defaultValues,
        localizedTexts,
        openAgb,
        openDatenschutz,
        hasAgb,
        hasDatenschutz,
        rsInsurancesOptions,
        gutachterOptions,
        gutachterGetData,
        setPostalCodeGutachter,
        gutachterIsLoading,
    }
    return <DynamicFormContext.Provider value={{ ...values }}>
        <FormSaveEffect {...props}>
            {children}
        </FormSaveEffect>
    </DynamicFormContext.Provider>
}

DynamicFormProvider.propTypes = {
    children: PropTypes.node.isRequired,
    formElements: PropTypes.array.isRequired,
    validationTexts: PropTypes.object,
    publicFileInfo: PropTypes.object,
    localizedTexts: PropTypes.object,
    openAgb: PropTypes.func,
    openDatenschutz: PropTypes.func,
    hasAgb: PropTypes.bool,
    hasDatenschutz: PropTypes.bool,
}