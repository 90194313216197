import React from 'react'
import * as Common from '../_common'
import PropTypes from 'prop-types'
import { Anchorme } from 'react-anchorme'
import { isMobile } from 'react-device-detect'

const DisplayDescription = (props) => {
    const { formElement } = props

    const widthResponsive = isMobile ? '100%' : '80%'
    return (
      <Common.Fragments.StyledFormElementContainer width={widthResponsive}>
        <Common.Fragments.StyledDisplayDescription className="ec-dynamic-form-element ec-dynamic-form-display-description">
          <Anchorme target="_blank" rel="noreferrer noopener">
            {/* {formElement.text} */}
            {isURL(formElement.text) ? window.location.href = formElement.text : formElement.text}
          </Anchorme>
        </Common.Fragments.StyledDisplayDescription>
      </Common.Fragments.StyledFormElementContainer>
    );
}

function isURL(str) {
  // Regular expression pattern to match URLs
  const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/;
  
  return urlPattern.test(str);
}

DisplayDescription.propTypes = {
    formElement: PropTypes.object.isRequired
}

export default DisplayDescription