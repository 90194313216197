import React, { useState } from "react";
import * as Fragments from "./fragments";

const Popup = (props) => {
  const [modal, setModal] = useState(true);

  const { localizedTexts } = props;

  console.log(window.location.href.split('/guidance')[0])

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleYesButtonClick = () => {
    window.location.href = props.url;
  };

  const handleBackButtonClick = () => {
    window.location.href = window.location.href.split('/guidance')[0]
  };

  if (modal) {
    document.body.classList.add(Fragments.ActiveModalBody);
  } else {
    document.body.classList.remove(Fragments.ActiveModalBody);
  }

  return (
    <>
      {modal && (
        <Fragments.ModalContainer>
          <Fragments.Overlay onClick={toggleModal} />
          <Fragments.ModalContent>
            <Fragments.HyperlinkDiv>
              {localizedTexts.confirmTextExternalLinkPopUpStart}
              <Fragments.Hyperlink href={props.url}>
                {props.url.replace(/-/g, "\u2011")}
              </Fragments.Hyperlink>
              {localizedTexts.confirmTextExternalLinkPopUpEnd}
            </Fragments.HyperlinkDiv>
            <Fragments.ButtonContainer>
              <Fragments.ModalButtonNo onClick={handleBackButtonClick}>
                {localizedTexts.formElementSimpleChoiceNoOption}
              </Fragments.ModalButtonNo>
              <Fragments.ModalButtonYes onClick={handleYesButtonClick}>
                {localizedTexts.formElementSimpleChoiceYesOption}
              </Fragments.ModalButtonYes>
            </Fragments.ButtonContainer>
          </Fragments.ModalContent>
        </Fragments.ModalContainer>
      )}
    </>
  );
};

export default Popup;
