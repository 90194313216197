import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const StyledButton = styled.button`
    padding: 0px;
    margin: 0px;
    background-color: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonTextColor};
    border: 1px solid ${props => props.theme.buttonBackground};
    cursor: pointer;
    outline: 0;
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    z-index: 0;
    min-height: 0px;
    min-width: 80px;
    border-radius:5px;
    height: 60px;
    justify-content: center;
    padding: 19px 24px;
    opacity: 1;
    max-width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    visibility: visible;
    text-size-adjust: none;
`

const Button = (props) => {
    const { handleSubmit, onSubmit, onValidationError } = props
    const theme = useTheme()
    const [isHover, setIsHover] = useState(false)
    return (
        <StyledButton
            style={{
                backgroundColor: isHover ? theme.buttonBackgroundHover : theme.buttonBackground,
                borderColor: isHover ? theme.buttonBackgroundHover : theme.buttonBackground,
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={handleSubmit(onSubmit, onValidationError)}
        >
            senden
        </StyledButton>
    )
}

Button.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValidationError: PropTypes.func,
}

export default Button
