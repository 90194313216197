export const cleanUpPostProcessor = (obj, cleanUpKeys) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || obj[propName].length === 0) {
            delete obj[propName]
        }
    }
    for (const key of cleanUpKeys) {
        delete obj[key]
    }
    return obj
}