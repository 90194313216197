import React, { useState } from 'react'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import * as Common from '../_common'
import styled from 'styled-components'

const StyledInputPhoneWrapper = styled.div`
  width: 100%;

  & .intl-tel-input {
    width: 100%;
    height: 38px;
  }

  & .selected-flag {
    z-index: 1;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & input {
    flex: 1 1;
    min-width: 0;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.borderColorInput};
    padding-left: 48px !important;
    width: 100%;
    height: 36px;
    &:hover {
        border-bottom: 1px solid ${(props) => props.theme.activeBorderColor};
    }
    &:focus {
      outline: none;
      border-bottom: 1px solid ${(props) => props.theme.activeBorderColor};
    }
  }
`

const InputPhoneNumber = (props) => {
    const {
        htmlId,
        validate = null,
        error,
        hideValidationMessage = false,
        formElement,
        control,
        defaultValue = '',
        setValue = () => {},
        validationTexts,
        iterElement
    } = props
    const [isValid, setIsValid] = useState(true)

    if(!window.intlTelInputUtils){
        return <div>intlTelInputUtils library missing</div>
    }

    const { getNumberType, numberType } = window.intlTelInputUtils
    const getNumberTypeString = (type) => Object.keys(numberType).find(key => numberType[key] === type)

    const isValidNumberType = (fullNumber) => {
        if(fullNumber === ''){
            return true
        }
        const currentNumberType = getNumberTypeString(getNumberType(fullNumber))
        return currentNumberType === 'MOBILE' //|| currentNumberType === 'FIXED_LINE_OR_MOBILE' || currentNumberType === 'FIXED_LINE'
    }

    const validationMessage = validationTexts.validMobileNumber

    const mergedValidate = {
        validatePhone: () => {
            return isValid || validationMessage
        },
        ...validate
    }

    return (
        <Common.FormElementWrapper
            htmlId={htmlId}
            formElement={formElement}
            hideValidationMessage={hideValidationMessage}
            error={error}
            iterElement={iterElement}
        >
            <Controller
                control={control}
                name={formElement.name}
                rules={{ validate: mergedValidate }}
                defaultValue={defaultValue}
                render={({ field: { onChange } }) => <StyledInputPhoneWrapper>
                    <IntlTelInput
                    nationalMode={false}
                    format={false}
                    fieldName={formElement.name}
                    defaultValue={defaultValue}
                    onPhoneNumberChange={(valid, val, countryData, fullNumber) => {
                        setValue(formElement.name, fullNumber)
                        setIsValid((valid || val === '') && isValidNumberType(fullNumber))
                        onChange(fullNumber)
                    }}
                    defaultCountry='de'
                    fieldId={htmlId}
                    autoHideDialCode={true}
                    separateDialCode={false}
                    preferredCountries={['de', 'at', 'ch']}
                />
                </StyledInputPhoneWrapper>}
            />
        </Common.FormElementWrapper>
    )
}

InputPhoneNumber.propTypes = {
    htmlId: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
    ]),
    formElement: PropTypes.object.isRequired,
}

export default InputPhoneNumber
