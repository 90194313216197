export const theme = {
    formElementBackground: '#f3f6f8',
    formContainerBackground: '#fff',
    formElementBorderRadius: '10px',
    formElementBorder: '0',
    buttonBackground: '#009a92',
    buttonBackgroundHover: '#009a92',
    buttonTextColor: '#fff',
    fontFamily: '"Lato", Arial, sans-serif',
    validationColor: '#bc3640',
    labelFontSize: '14px',
    fontFamilyHeadline: '"Lato", Arial, sans-serif',
    fontSizeHeadline: '24px',
    fontWeightHeadline: 'bold',
    fontSizeDisplay: '16px',
    fontWeightDisplay: '300',
    inputPaddingLeft: '0px',
    activeBorderColor: '#000000',
    focusBorderColor: '#000000',
    lineHeightDisplay: '24px',
    borderColorInput: '#d9d9d9',
    backgroundLabel: '#ececec',
    policyTextColor: '#bc3640'
}
