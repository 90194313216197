import { FormElementFallback } from './_fallback'
import { Input } from './input'
import { Button } from './button'
import { Rating } from './rating'
import { FORM_ELEMENT_TYPE } from '../constants'
import { Textarea } from './textarea'
import { Time } from './time'
import { DateComponent } from './date'
import { Multiplechoice } from './multiplechoice'
import { Simplechoice } from './simplechoice'
import { Checkbox } from './checkbox'
import { InputNumber } from './input_number'
import { Headline } from './headline'
import { DisplayDescription } from './display_description'
import { Hidden } from './hidden'
import { InputRegex } from './input_regex'
import { InputPhoneNumber } from './input_phone_number'
import { Signature } from './signature'
import { GeoLocationForm } from './geolocation'
import { Uploader } from './uploader'
import { ExternalRequests } from './external_requests'
import { Rechtsschutzformular } from './rechtsschutzformular'
import AgbDatenschutzComponent from './agb_datenschutz'
import Repeater from './repeater'
import ExternalLink from './external_link'
import CustomFormTemplate from './custom_form_template'
import { Gutachter } from './gutachter'

export const formComponentsMapper = (type) => {
    switch (type) {
    case FORM_ELEMENT_TYPE.INPUT:
        return Input
    case FORM_ELEMENT_TYPE.RATING:
        return Rating
    case FORM_ELEMENT_TYPE.TEXTAREA:
        return Textarea
    case FORM_ELEMENT_TYPE.TIME:
        return Time
    case FORM_ELEMENT_TYPE.DATE:
        return DateComponent
    case FORM_ELEMENT_TYPE.MULTIPLECHOICE:
        return Multiplechoice
    case FORM_ELEMENT_TYPE.SIMPLECHOICE:
        return Simplechoice
    case FORM_ELEMENT_TYPE.CHECKBOX:
        return Checkbox
    case FORM_ELEMENT_TYPE.NUMBER:
        return InputNumber
    case FORM_ELEMENT_TYPE.HEADLINE:
        return Headline
    case FORM_ELEMENT_TYPE.DISPLAY:
        return DisplayDescription
    case FORM_ELEMENT_TYPE.INPUTREGEX:
        return InputRegex
    case FORM_ELEMENT_TYPE.MOBILENUMBER:
        return InputPhoneNumber
    case FORM_ELEMENT_TYPE.SIGNATURE:
        return Signature
    case FORM_ELEMENT_TYPE.GEOLOCATION:
        return GeoLocationForm
    case FORM_ELEMENT_TYPE.UPLOADER:
        return Uploader
    case FORM_ELEMENT_TYPE.ER_LOGIN_FORM:
        return ExternalRequests
    case FORM_ELEMENT_TYPE.ER_LOGIN_FORM_LONG:
        return ExternalRequests
    case FORM_ELEMENT_TYPE.AKTENRUBRUM:
        return ExternalRequests
    case FORM_ELEMENT_TYPE.GEGNERFORMULARONEKFZ:
        return ExternalRequests
    case FORM_ELEMENT_TYPE.ER_CONCERN_FORM:
        return ExternalRequests
    case FORM_ELEMENT_TYPE.ER_GEGNERFORMULAR:
        return ExternalRequests
    case FORM_ELEMENT_TYPE.BUTTON:
        return Button
    case FORM_ELEMENT_TYPE.HIDDEN:
        return Hidden
    case FORM_ELEMENT_TYPE.AGB_DATENSCHUTZ:
        return AgbDatenschutzComponent
    case FORM_ELEMENT_TYPE.RECHTSSCHUTZFORMULAR:
        return Rechtsschutzformular
    case FORM_ELEMENT_TYPE.REPEATER:
        return Repeater
    case FORM_ELEMENT_TYPE.EXTERNAL_LINK:
        return ExternalLink
    case FORM_ELEMENT_TYPE.CUSTOM_FORM_TEMPLATE:
        return CustomFormTemplate
    case FORM_ELEMENT_TYPE.GUTACHTER_CHOICE:
        return Gutachter
    default:
        return FormElementFallback
    }
}
