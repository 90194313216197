import styled from "styled-components";
import deleteIcon from './images/delete.png'

export const RepeaterWrapper = styled.div`
  border: 2px solid #969693;
  width: 100%;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
  opacity: 0.8;
`;
export const AddButton = styled.button`
  width: ${props => props.width};
  background-color: #009a92;
  border: 0;
  padding: 15px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  font-size: 17px;
  cursor: pointer;
`;

export const AddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RemoveButton = styled.button`
  background: url(${deleteIcon}) no-repeat;
  background-size: 24px;
  content: "";
  border: 0;
  width: 24px;
  height: 24px;
  padding: 8px 4px;
  margin-right: 12px;
  margin-bottom: 6px;
  cursor: pointer;
`;

export const RemoveButtonDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;


