export const fetchRequestBuilder = (url, payload = {}, token, omitToken = false) => {
    const apiKey = process.env.REACT_APP_API_KEY
    const applicationKey = process.env.REACT_APP_APPLICATION_KEY
    
    let data = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'X-Application-Key': applicationKey,
            'X-API-Key': apiKey,
        },
        mode: 'cors',
        body: JSON.stringify(payload)
    }
    
    data.headers = !omitToken ? {
        ...data.headers,
        'Authorization': 'Bearer ' + token,
    } : data.headers

    return {
        data,
        url
    }
}

export default fetchRequestBuilder