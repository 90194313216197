import React from 'react'
import * as Common from '../_common'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { useTheme } from 'styled-components'

const Headline = (props) => {
    const { formElement } = props
    const widthResponsive = isMobile ? '100%' : '80%'
    const theme = useTheme()
    return <Common.Fragments.StyledFormElementContainer width={widthResponsive}>

        <Common.Fragments.StyledHeadline className='ec-dynamic-form-element ec-dynamic-form-headline' style={{ fontFamily: theme.fontFamilyHeadline }}>
            {formElement.text}
        </Common.Fragments.StyledHeadline>
    </Common.Fragments.StyledFormElementContainer>
}

Headline.propTypes = {
    formElement: PropTypes.object.isRequired
}

export default Headline
