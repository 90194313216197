import React from 'react'
import PropTypes from 'prop-types'
import { FormElement } from '..'
import { useDynamicFormContext } from '../../contexts/use_dynamic_form_context'

export const DynamicExternalRequests = ({
    formElements,
    ...props
}) => {
    const { onValidationError } = props
    const { setForm, watch, register, errors, getValues, setValue, control } = useDynamicFormContext()
    // change default values from parent values

    return formElements.map((f) => {
        return <FormElement
            watch={watch}
            setForm={setForm}
            error={f.type === 'file' ? errors['formelement_' + f.id] : errors[f.name] ? errors[f.name] : {}}
            value={f.type === 'file' ? getValues()['formelement_' + f.id] : getValues()[f.name] ? getValues()[f.name] : ''}
            register={register}
            control={control}
            setValue={setValue}
            onValidationError={onValidationError}
            formElement={f}
            key={f.id}
            getValues={getValues}
        />
    })
}

DynamicExternalRequests.propTypes = {
    formElements: PropTypes.array.isRequired,
}
