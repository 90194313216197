export const fileUploadRequestBuilder = (url, file, token, omitToken = false) => {
    const apiKey = process.env.REACT_APP_API_KEY
    const applicationKey = process.env.REACT_APP_APPLICATION_KEY
    
    let formData = new FormData()
    if (file !== null) {
        formData.append('File', file, file.name)
        formData.append('Name', file.name)
    }
    
    let data = {
        method: 'POST',
        headers: { 
            'X-API-Key': apiKey,
            'X-Application-Key': applicationKey
        },
        mode: 'cors',
        body: formData
    }
    
    data.headers = !omitToken ? {
        ...data.headers,
        'Authorization': 'Bearer ' + token
    } : data.headers

    return {
        data,
        url
    }
}