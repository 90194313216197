import React from 'react'
import PropTypes from 'prop-types'

export const FormElementFallback = props => {
    const { formElement } = props
    return <div>No form element component found for type: "{formElement.type}"</div>
}

FormElementFallback.propTypes = {
    formElement: PropTypes.object.isRequired
}
