export const writeUploadIds = (formData, formElementKey, fileId, uploadId) => {
    const formElement = formData.formInput[formElementKey]
    const fullFiles = formElement.filter(o => o.attachment !== undefined)
    const uploadIds = formElement.filter(o => o.attachment === undefined)
    const foundedObj = fullFiles.filter(o => o.attachment.fileId !== fileId).concat(uploadId)
    const mergedUploadIds = uploadIds.concat(foundedObj)
    const newObj = Object.fromEntries(new Map([
        [formElementKey, mergedUploadIds],
    ]))

    formData.formInput = { ...formData.formInput, ...newObj }
    return formData
}