import styled from 'styled-components'
import calendarIcon from './images/calendar.svg'
import { isMobile } from 'react-device-detect'

export const DateContainer = styled.div`
    width: 30%;
    margin: 0;
    padding: 0;
    position: relative;

    & input{
        color: ${props => props.theme.color.lightPowderGray};
        border: none;
        border-bottom: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.lightPowderGray};
        height: 38px;
        padding-left: 8px;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        box-sizing: border-box;

        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
            height: 41px;
            font-size: ${props => props.theme.fontSize.medium18};
        }
        
        &:hover {
            border-bottom: 1px solid ${props => props.disabled ? props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red : props.theme.color.lightPowderGray};
        }

        &:active {
            border-bottom: 1px solid ${props => props.disabled ? props.theme.color.gray15 : props => props.theme.color.anthracite};
        }

        &:disabled {
            background-color: ${props => props.theme.color.gray5};
            color: ${props => props.theme.color.anthracite50};
        }

        &:focus{
            outline: none;
        }

        &:invalid{
            border-bottom: 1px solid ${props => props.hasPattern ? props.theme.color.red : props.theme.color.gray15};
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
`


export const InputDateWrapper = styled.div`
    width: 100%;
    margin: 100;
    padding: 0;
    position: relative;

    .react-date-picker {
        width: 100%
    }

    &:after{
        background: url(${calendarIcon}) no-repeat center;
        background-size: 16px;
        content: "";
        right:0;
        position: absolute;
        width: 18px;
        height: 18px;
        padding: 1px;
        margin: 8px;
        cursor: pointer;
    }

    .react-date-picker__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;

        background-color: white;
        padding: 2px 8px;
        width: 100%;
        font-size: ${props => props.theme.fontSize.standard};
        font-weight: ${props => props.theme.fontWeight.standardRegular};
        color: ${props => props.theme.color.anthracite};
        border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
        box-shadow: none;
        margin-top: 4px;
        height: 38px;
        border-radius: 5px;
        box-sizing: border-box;


        &:hover {
            border: 1px solid ${props => props.disabled ? props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red : props.theme.color.lightPowderGray};
        }
    }
`


export const StyledDateWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;

    .react-date-picker {
        width: 100%
    }

    .rdrCalendarWrapper {
        position: absolute;
        z-index: 1001
    }

    .react-date-picker__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;

        background-color: white;
        padding: 2px 8px;
        width: 100%;
        font-size: ${props => props.theme.fontSize.standard};
        font-weight: ${props => props.theme.fontWeight.standardRegular};
        color: ${props => props.theme.color.anthracite};
        border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
        box-shadow: none;
        margin-top: 4px;
        height: 38px;
        border-radius: 5px;
        box-sizing: border-box;


        &:hover {
            border: 1px solid ${props => props.disabled ? props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red : props.theme.color.lightPowderGray};
        }
    }

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        .rdrCalendarWrapper {
            font-size: 12px;
        }
    }
    @media (max-width: ${props => props.theme.breakpoint.mobileL}px) {
        .rdrCalendarWrapper {
            font-size: 12px;
        }

        .rdrCalendarWrapper {
            left: -19px !important;
        }
    }
    @media (max-width: ${props => props.theme.breakpoint.mobileM}px) {
        .rdrCalendarWrapper {
            font-size: 10px;
        }

        .rdrCalendarWrapper {
            left: -22px !important;
        }
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
`

export const DateContainerCustom = styled.div`
    width: ${isMobile ? '75%' : '30%'};
    display: flex;
    position: relative;
    border-bottom: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.borderColorInput};
    padding: 14px 0 12px 0;
    justify-content: center;
`

export const InputDateCustom = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
    ::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }
`

export const InputDateExample = styled.div`
    .react-date-picker__wrapper {
        display: none
    }
`