import React from 'react'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import GeoLocationForm from './component'

const centerGermany = {
    lat: 51.165691,
    lng: 10.451526
}

const GeolocHoc = (props) => {
    const [position, setPosition] = useState(null)
    const [grantedPosition, setGrantedPosition] = useState(false)
    const [grantedAccessed, setGrantedAccessed] = useState(false)
    function getCoords() {
        return new Promise((resolve, reject) =>
            navigator.permissions ?

                // Permission API is implemented
                navigator.permissions.query({
                    name: 'geolocation'
                }).then(permission =>
                    // is geolocation granted?
                    permission.state === 'granted'
                        ? navigator.geolocation.getCurrentPosition(pos => resolve(pos.coords))
                        : resolve(null)
                ) :

                // Permission API was not implemented
                reject(new Error('Permission API is not supported'))
        )
    }
    useEffect(() => {
        getCoords().then(coords => {
            setGrantedAccessed(true)
            coords !== null ? setPosition({ lat: coords.latitude, lng: coords.longitude }) : setPosition({ lat: centerGermany.lat, lng: centerGermany.lng })
            setGrantedPosition(coords !== null)
        })
    }, [])
    
    if (grantedAccessed && position !== null) {
        const newProps = {
            ...props,
            position,
            grantedPosition,
        }
        return <GeoLocationForm {...newProps} />
    }

    return <Fragment />
}


export default GeolocHoc
