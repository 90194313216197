import React from 'react'
import PropTypes from 'prop-types'

const Hidden = (props) => {
    const { htmlId, register, validate = null, defaultValue = '', formElement } = props
    return <input type='hidden' id={htmlId} name={formElement.name} defaultValue={defaultValue} {...register(formElement.name, {
        validate
    })} className='ec-dynamic-form-element ec-dynamic-form-element-type-hidden' />
}

Hidden.propTypes = {
    htmlId: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    validate: PropTypes.func,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired
}

export default Hidden
