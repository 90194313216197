import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { DynamicExternalRequests } from './dynamic_external_requests'

const ExternalRequests = (props) => {
    const { formElement } = props

    return <DynamicExternalRequests formElements={formElement.formElements} {...props} />
}

ExternalRequests.propTypes = {
    formElement: PropTypes.object.isRequired,
}


export default withTheme(ExternalRequests)
