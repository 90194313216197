import styled from "styled-components";

export const ActiveModalBody = styled.div`
  overflow-y: hidden;
`;

export const ModalButtonNo = styled.button`
  padding: 10px 20px;
  font-size: 12px;
  margin-right: 5px;
  background: #cc2a27;
  color: white;
  border-radius: 10px;
  border: 0;
  border-width: 0;

`;

export const ModalButtonYes = styled.button`
  padding: 10px 20px;
  font-size: 12px;
  margin-right: 5px;
  background: #429F98;
  color: white;
  border-radius: 10px;
  border: 0;
  border-width: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  backdrop-filter: brightness(20%);
  z-index: 999;
  
`;

export const Overlay = styled.div`
  background: rgba(49, 49, 49, 0.8);
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #fff;
  padding: 14px 28px;
  border-radius: 10px;
  max-width: 600px;
  min-width: 250px;
  text-align: justify;
`;

export const HyperlinkDiv = styled.h2`
  font-size: 16px;
`;

export const Hyperlink = styled.a`
  text-decoration: none;
`;
  
