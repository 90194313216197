import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { theme } from './theme'
import { DynamicForm } from './dynamic_form'
import { GlobalStyle } from './global_style'
import { DynamicFormProvider } from './contexts/dynamic_form_context'
import { dataGroup } from './data_group'
import { agbDatenschutzObject, customFormElements } from './helpers'

const FormWrapper = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const Root = (props) => {
    const {
        formThemeOverride = {},
        formData,
        hasAgb = false,
        hasDatenschutz = false,
        rsInsurancesOptions = [],
        gutachterOptions = [],
        ...rest
    } = props

    const { localizedTexts, validationTexts, publicFileInfo } = dataGroup(rest)
    const agbDatenschutzFormElements = agbDatenschutzObject(hasAgb, hasDatenschutz, localizedTexts.agbStatement, localizedTexts.datenschutzStatement)
    const customizedFormElements = customFormElements(hasAgb, hasDatenschutz, formData.formElement.formElements, agbDatenschutzFormElements)
    return <ThemeProvider theme={{ ...theme, ...formThemeOverride }}>
        <GlobalStyle />
        <FormWrapper className='ec-dynamic-form-root'>
            <DynamicFormProvider rsInsurancesOptions={rsInsurancesOptions} gutachterOptions={gutachterOptions} localizedTexts={localizedTexts} formElements={customizedFormElements} validationTexts={validationTexts} publicFileInfo={publicFileInfo} hasAgb={hasAgb} hasDatenschutz={hasDatenschutz} {...rest}>
                <DynamicForm {...rest} formElements={customizedFormElements} />
            </DynamicFormProvider>
        </FormWrapper>
    </ThemeProvider>
}

Root.propTypes = {
    formThemeOverride: PropTypes.object,
    formData: PropTypes.object.isRequired,
    hasAgb: PropTypes.bool,
    hasDatenschutz: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onValidationError: PropTypes.func,
    validationTextsOverride: PropTypes.object,
    publicFileInfoOverride: PropTypes.object,
    option: PropTypes.array,
}

export default Root
