import React from 'react'
import { Fragment } from 'react'
import { useState } from 'react'
import DragAndDropUploader from './drag_and_drop'
import SimpleUploader from './simple_uploader'
import { FlexGrid, FlexGridItem } from '../../grid'
import AttachmentThumbnailComponent from '../../helpers/thumbnail'
import { Controller } from 'react-hook-form'
import * as Common from '../_common'
import PropTypes from 'prop-types'
import FileText from './images/file-text.png'

const Uploader = (props) => {
    const { setValue, control, validate, htmlId, error, hideValidationMessage = false, formElement, value, localizedTexts, iterElement } = props
    const [uploadQueue, setUploadQueue] = useState([])
    const [selected, setSelected] = useState(null)
    let keyValue = 'formelement_' + formElement.id
    if(formElement.uploaderKey) keyValue = formElement.uploaderKey
    const handleAddedFiles = (files, onChange) => {
        const fileWithKey = files.map(f => { return { ...f, keyValue } })
        if (value === undefined) {
            setValue(keyValue, [...fileWithKey])
            onChange([...fileWithKey])
        } else {
            setValue(keyValue, [...value, ...fileWithKey])
            onChange([...value, ...fileWithKey])
        }
        files.forEach(file => {
            setUploadQueue(x => [...x, {
                ...file,
                keyValue
            }])
        })
    }
    const deleteFile = (file, onChange) => {
        const filesDeleted = uploadQueue.filter(u => u.attachment.fileId !== file.fileId)
        setValue(keyValue, [...filesDeleted])
        onChange([...filesDeleted])
        setUploadQueue(filesDeleted)
    }
    const filled = uploadQueue.length !== 0
    return <Common.FormElementWrapper
        htmlId={htmlId}
        formElement={formElement}
        hideValidationMessage={hideValidationMessage}
        error={error}
        iterElement={iterElement}
    >
        <Controller
            name={keyValue}
            control={control}
            rules={{ validate }}
            render={({ field: { onChange } }) => (
                <DragAndDropUploader name={keyValue} htmlId={htmlId} filled={filled} onFileAdded={() => { }} onFilesAdded={(files) => { handleAddedFiles(files, onChange) }}>
                    {
                        !filled ? <Fragment>
                            <FlexGrid justifyCenter itemsCenter directionColumn>
                                <FlexGridItem justifyCenter>
                                    <img 
                                        src={FileText}
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                            marginBottom: '8px',
                                        }}
                                    />
                                </FlexGridItem>
                                <FlexGridItem justifyCenter>
                                    <p>{localizedTexts.dragFileText ? localizedTexts.dragFileText : 'Drag your files or images here to start uploading'}</p>
                                </FlexGridItem>
                                <FlexGridItem justifyCenter>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        marginBottom: '8px',
                                    }}>
                                        <span
                                            style={{
                                                height: '1px',
                                                backgroundColor: '#d9d9d9',
                                                width: '40px',
                                            }}
                                        ></span>
                                        {localizedTexts.uploadFileText ? 'ODER' : 'OR'}
                                        <span
                                            style={{
                                                height: '1px',
                                                backgroundColor: '#d9d9d9',
                                                width: '40px',
                                            }}
                                        ></span>
                                    </div>
                                </FlexGridItem>
                                <FlexGridItem justifyCenter>
                                    <SimpleUploader
                                        label={localizedTexts.uploadFileText ? localizedTexts.uploadFileText : 'Upload file or image'}
                                        onFileAdded={() => { }}
                                        onFilesAdded={(files) => handleAddedFiles(files, onChange)}
                                    />
                                </FlexGridItem>
                            </FlexGrid>
                        </Fragment> : <FlexGrid directionColumn>
                            <FlexGridItem>
                                <FlexGrid>
                                    {uploadQueue.map((file, fileIndex) => {
                                        return <AttachmentThumbnailComponent
                                            attachment={file.attachment}
                                            onAttachmentClick={file => {
                                                setSelected(file)
                                            }}
                                            isActive={selected !== null && selected.fileId === file.attachment.fileId}
                                            key={fileIndex}
                                            onDeleteFile={file => deleteFile(file, onChange)}
                                        />
                                    })}
                                </FlexGrid>
                            </FlexGridItem>
                            <FlexGridItem justifyCenter>
                                <SimpleUploader
                                    label={localizedTexts.uploadFileText ? localizedTexts.uploadFileText : 'Upload file or image'}
                                    onFileAdded={() => { }}
                                    onFilesAdded={(files) => handleAddedFiles(files, onChange)}
                                />
                            </FlexGridItem>
                            <FlexGridItem justifyCenter>
                                <small style={{ fontStyle: 'italic', fontWeight: 'bolder' }}>
                                    {localizedTexts.dragFileText ? localizedTexts.dragFileText : 'Drag your files or images here to start uploading'}
                                </small>
                            </FlexGridItem>
                        </FlexGrid>
                    }
                </DragAndDropUploader>
            )}
        />
    </Common.FormElementWrapper>
}

Uploader.propTypes = {
    htmlId: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    validate: PropTypes.func,
    watch: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired
}

export default Uploader