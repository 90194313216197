import {
    cleanUpPostProcessor,
    fetchRequestBuilder,
    webAkteDataStructurePostProcessor
} from './post_processor_methods/index.js'

import {
    getFilesFromFormData,
    fileUploadRequestBuilder,
    handleApiResponse,
    writeUploadIds,
    limiter,
    fetchHandler
} from './file_upload_helpers'

export class FormPostProcessor {
    constructor(
        formData,
        accessToken,
        apiDomain,
        cleanUpKeys = [],
        apiVersion = 'v2.0',
        apiEndpoint = {
            form: 'assistants/process',
            fileUpload: 'files/upload'
        },
    ) {
        this.formData = formData
        this.rawFormData = formData
        this.cleanUpPostProcessor = cleanUpPostProcessor
        this.webAkteDataStructurePostProcessor = webAkteDataStructurePostProcessor
        this.rateLimiter = limiter
        this.files = getFilesFromFormData(formData)
        this.apiVersion = apiVersion
        this.apiDomain = apiDomain
        this.apiEndpoint = apiEndpoint
        this.accessToken = accessToken
        this.requestBuilder = fileUploadRequestBuilder
        this.postFormRequestBuilder = fetchRequestBuilder
        this.handleResponse = handleApiResponse,
        this.cleanUpKeys = cleanUpKeys
    }

    //setters
    setRateLimiter(rateLimiter) {
        this.rateLimiter = rateLimiter
        return this
    }

    //getters
    getFormData() {
        return this.formData
    }

    getRawFormData() {
        return this.rawFormData
    }

    getRawFiles() {
        return this.files
    }

    getUploadUrl() {
        return `${this.apiDomain}${this.apiVersion}/${this.apiEndpoint.fileUpload}`
    }

    getPostFormUrl() {
        return `${this.apiDomain}${this.apiEndpoint.form}`
    }

    //post processors
    cleanUp() {
        this.formData = this.cleanUpPostProcessor(this.formData, this.cleanUpKeys)
        return this
    }

    writeWebAkteStructure() {
        this.formData = this.webAkteDataStructurePostProcessor(this.formData)
        return this
    }

    processForm() {
        return this.cleanUp().writeWebAkteStructure()
    }

    //sending form process
    writeUploadIds(formElementKey, fileId, fileuploadId, response) {
        this.formData = writeUploadIds(this.formData, formElementKey, fileId, fileuploadId)
        return response
    }

    *sendForm(afterSending = function* () { }) {
        const { url, data } = yield this.postFormRequestBuilder(this.getPostFormUrl(), this.getFormData(), this.accessToken)
        const response = yield this.rateLimiter.schedule(() => fetchHandler(url, data))
            .then(this.handleResponse)
        yield afterSending(response)
        return response
    }

    *uploadFileByIndex(i) {
        const file = this.files[i]
        const { url, data } = yield this.requestBuilder(this.getUploadUrl(), file.metaData, this.accessToken)

        const response = yield this.rateLimiter.schedule(() => fetchHandler(url, data))
            .then(this.handleResponse)
            .then(resp => this.writeUploadIds(file.keyValue, file.attachment.fileId, resp.files[0].fileuploadId, resp))
        return response
    }

    *uploadFiles(
        beforeUploads = function* () { },
        onUploadStarted = function* () { },
        onUploadFinished = function* () { },
        afterUploads = function* () { }
    ) {
        const files = this.files
        const responses = []

        if (files.length > 0) {
            yield beforeUploads(files)

            for (let index = 0; index < files.length; index++) {
                yield onUploadStarted(files[index])
                const response = yield this.uploadFileByIndex(index)
                yield responses.push(response)
                yield onUploadFinished(files[index], response)
            }

            yield afterUploads(files, responses)
        }
        return responses
    }
}