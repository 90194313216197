import styled from 'styled-components'

export const SignatureWrapper = styled.div`
  position: relative;
  width: 400px;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 10px;
`

export const SignaturePadCanvas = styled.canvas`
    position: absolute;
  left: 0;
  top: 0;
  width:400px;
  height:200px;
`

export const ButtonContainer = styled.div`
    & button{
        border-color: #009a92;
        border-radius:5px;
        padding: 6px;
        cursor: pointer;
        background-color: ${props => props.theme.buttonBackground};
        color: ${props => props.theme.buttonTextColor};
        font-weight: 700;
        border: 0;
        width: 49%;
        display: inline-block;
        &:last-child{
          margin-left: 2%;
        }
    }
        & .save-button-ec-dynamicForm-sign {
          background-color: ${props => props.saved ? props.theme.buttonBackground + '50' : props.theme.buttonBackground};
        }
        & .delete-button-ec-dynamicForm-sign {
          background-color: ${props => !props.saved ? props.theme.buttonBackground + '50' : props.theme.buttonBackground};
        }
`