import React from 'react'
import PropTypes from 'prop-types'
import * as Common from '../_common'
import Check from './check'
import { isMobile } from 'react-device-detect'

const Checkbox = (props) => {
    const { htmlId, control, validate = null, error, hideValidationMessage = false, defaultValue = '', formElement } = props
    const widthWrapperResponsive = isMobile ? '100%' : '80%'

    return <Common.Fragments.CheckboxWrapper width={widthWrapperResponsive}>
        <Common.Fragments.CheckboxContentWrapper>
            <Common.Fragments.CheckboxContent width={'32px'}>
                <Check validate={validate} htmlId={htmlId} formElement={formElement} defaultValue={defaultValue} control={control} />
            </Common.Fragments.CheckboxContent>
            <Common.Fragments.CheckboxContent>
                {formElement.text}
            </Common.Fragments.CheckboxContent>
        </Common.Fragments.CheckboxContentWrapper>
        <Common.ValidationErrorMessage hideValidationMessage={hideValidationMessage} error={error} />
    </Common.Fragments.CheckboxWrapper>
}


Checkbox.propTypes = {
    htmlId: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    formElement: PropTypes.object.isRequired
}

export default Checkbox
