import styled from 'styled-components'

export const RadioWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 10px;

/* HIDE RADIO */
input[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


/* IMAGE STYLES */
input[type=radio] + img {
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.borderColorInput};
  width: ${p => p.large.width};
  height:${p => p.large.height};
  border-radius: 20px;
  margin-left: 8px;
  padding: 20px;
}

/* CHECKED STYLES */
input[type=radio]:checked + div {
  width: ${p => p.large.width};
  height:${p => p.large.height};
  border-radius: 20px;
  background: #e7f4f3;
  border: 2px solid #009a92;
}
`