import React from 'react'
import RatingImage from './rating_image'
import * as Common from '../_common'
import PropTypes from 'prop-types'

const Rating = (props) => {
    const { validate, htmlId, error, hideValidationMessage = false, formElement, register, iterElement } = props

    return <Common.FormElementWrapper
        iterElement={iterElement}
        htmlId={htmlId}
        formElement={formElement}
        hideValidationMessage={hideValidationMessage}
        error={error}
    >
        <RatingImage validate={validate} htmlId={htmlId} name={formElement.name} register={register} />
    </Common.FormElementWrapper>
}

Rating.propTypes = {
    htmlId: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    setValue: PropTypes.func,
    validate: PropTypes.func,
    error: PropTypes.object,
    hideValidationMessage: PropTypes.bool,
    formElement: PropTypes.object.isRequired
}

export default Rating