import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Common from '../_common';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { withTheme } from 'styled-components';
import * as Fragments from './fragments';

const Rechtsschutzformular = (props) => {
  const {
    htmlId,
    setValue,
    validate = null,
    error,
    register,
    hideValidationMessage = false,
    control,
    formElement,
    theme,
    rsInsurancesOptions,
    iterElement,
    localizedTexts
  } = props;
  let defaultValue = '';
  const [haveInsurance, setHaveInsurance] = useState(false);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? '#404040' : '#d9d9d9',
      borderRadius: '5px',
      marginTop: '10px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#9a9eab',
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? theme.buttonBackground : '',
      '&:hover': {
        background: '#e6e6e6',
      },
    }),
  };
  return (
    <Common.FormElementWrapper
      htmlId={htmlId}
      formElement={formElement}
      hideValidationMessage={hideValidationMessage}
      error={error}
      iterElement={iterElement}
    >
      <Fragments.RsWrapper>
        <Common.Fragments.StyledSelectWrapper>
          <Common.Label
            htmlId={`${htmlId}_have_insurance`}
            label={localizedTexts.rsHaveInsuranceLabel}
          />
          <Select
            id={`${htmlId}_have_insurance`}
            defaultValue={false}
            styles={customStyles}
            placeholder='Bitte auswählen'
            isClearable={false}
            options={[
              {
                label: 'Ja',
                value: true,
              },
              {
                label: 'Nein',
                value: false,
              },
            ]}
            onChange={(val) => {
              setHaveInsurance(val.value)
            }}
            className='ec-dynamic-form-element ec-dynamic-form-element-type-multiplechoice'
          />
        </Common.Fragments.StyledSelectWrapper>
        {haveInsurance === true && (
          <>
            <Common.Fragments.StyledSelectWrapper>
              <Common.Label
                htmlId={formElement.formElements[0].id}
                label={'Bitte wählen Sie Ihren Rechtsschutzversicherer.'}
              />
              <Controller
                htmlId={formElement.formElements[0].id}
                name={formElement.formElements[0].name}
                control={control}
                rules={{ validate }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id={htmlId}
                    defaultValue={value}
                    styles={customStyles}
                    placeholder='Bitte auswählen'
                    isClearable={true}
                    options={rsInsurancesOptions}
                    onChange={(val) => {
                        setValue(formElement.formElements[0].name, val === null ? null : val.value)
                        onChange(val === null ? null : val.value)
                    }}
                    className='ec-dynamic-form-element ec-dynamic-form-element-type-multiplechoice'
                  />
                )}
              />
            </Common.Fragments.StyledSelectWrapper>
            <Common.Fragments.StyledTextInputWrapper>
              <Common.Label
                htmlId={formElement.formElements[1].id}
                label={localizedTexts.rsInsuranceNumberLabel}
              />
              <input
                id={formElement.formElements[1].id}
                name={formElement.formElements[1].name}
                defaultValue={defaultValue}
                {...register(formElement.formElements[1].name, {
                  validate,
                })}
                className='ec-dynamic-form-element ec-dynamic-form-element-type-input'
              />
            </Common.Fragments.StyledTextInputWrapper>
          </>
        )}
      </Fragments.RsWrapper>
    </Common.FormElementWrapper>
  );
};

Rechtsschutzformular.propTypes = {
  htmlId: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  validate: PropTypes.func,
  error: PropTypes.object,
  hideValidationMessage: PropTypes.bool,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
  ]),
  formElement: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

export default withTheme(Rechtsschutzformular);
