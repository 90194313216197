import styled from "styled-components";

export const PostalCodeInputWrapper = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: ${p => p.isMobile ? 'column' : 'row'};
    align-items:center;
    gap: 10px;
    margin: 20px 0px;
    width: 100%;
`

export const PostalCodeInputButton = styled.button`
    border: none;
    background-color: #009a92;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    color: white;
    border-radius:5px;
    width : ${p => p.isMobile ? '100%' : 'auto'}
`

export const PostalCodeError = styled.div`
    font-size: 10px;
    margin-top: -12px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #bc3640;
`