import { validationMethods } from './validation_methods'
import { FORM_ELEMENT_PROPS, FORM_ELEMENT_TYPE, REQUIRED_TYPE } from '../../constants'
import { validateMultiple } from './validation_helpers'

const mapRequiredTypeToValidationMethod = requiredType => {
    switch (requiredType) {
        case REQUIRED_TYPE.CUSTOMFORMRADIONOTEMPTY: return validationMethods.notEmptySelect
        case REQUIRED_TYPE.NUMERIC: return validateMultiple([validationMethods.notEmpty, validationMethods.mustBeNumeric])
        case REQUIRED_TYPE.NUMERIC_OPTIONAL: return validationMethods.mustBeNumeric
        case REQUIRED_TYPE.CHECKBOXREQUIRED: return validationMethods.notEmptyChecked
        case REQUIRED_TYPE.CUSTOMFORMDATE: return validationMethods.notEmptyDate
        case REQUIRED_TYPE.CUSTOMFORMTIME: return validationMethods.mustBeValidTime
        case REQUIRED_TYPE.CUSTOMFORMTIMEOPTIONAL: return validationMethods.noValidation
        case REQUIRED_TYPE.NOTEMPTY: return validationMethods.notEmpty
        case REQUIRED_TYPE.REGCHECK: return validateMultiple([validationMethods.notEmpty, validationMethods.mustBeValidRegex])
        case REQUIRED_TYPE.REGCHECKOPTIONAL: return validationMethods.mustBeValidRegex
        case REQUIRED_TYPE.MOBILENUMBER: return validateMultiple([validationMethods.notEmpty, validationMethods.mustBeValidPhoneNumber])
        case REQUIRED_TYPE.MOBILENUMBEROPTIONAL: return validationMethods.mustBeValidPhoneNumber
        case REQUIRED_TYPE.SIGNATURENOTEMPTY: return validationMethods.notEmptySignature
        case REQUIRED_TYPE.CUSTOMFORMGEOLOCATION: return validationMethods.mustBeValidLocation
        case REQUIRED_TYPE.GUTACHTERREQUIRED: return validationMethods.notEmpty
        default: return validationMethods.noValidation
    }
}

/**
 * Maps RequiredType to validation method
 *
 * @param {string} requiredType RequiredType from formElements-Table
 * @param {string} type Type of the FormElement
 * @returns {function} returns a function that takes the value and returns (true || 'error message')
 */

export const validationMapper = (requiredType, type, formElement) => {
    switch (type) {
        case FORM_ELEMENT_TYPE.UPLOADER:
            switch (requiredType) {
                case REQUIRED_TYPE.UPLOAD: return validateMultiple([validationMethods.notEmptyUpload, validationMethods.checkEachFileSize, validationMethods.checkTotalFilesSize, validationMethods.checkAllowedExtensionFile])
                default: return validateMultiple([validationMethods.checkEachFileSize, validationMethods.checkTotalFilesSize, validationMethods.checkAllowedExtensionFile])
            }
        case FORM_ELEMENT_TYPE.TEXTAREA: {
            let methods = [mapRequiredTypeToValidationMethod(requiredType)]
            if (formElement[FORM_ELEMENT_PROPS.MAXLENGTH] > 0) {
                methods = [...methods, validationMethods.maxLength]
            }
            return validateMultiple(methods)
        }
        default:
            return mapRequiredTypeToValidationMethod(requiredType)
    }
}