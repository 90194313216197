
import styled from 'styled-components'

export const StyledContainer = styled.label`
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    color: ${props => props.disabled ? props.theme.color.gray20 :  props.theme.color.standardRegular};
    align-items: center;
    align-content: center;
    justify-content: center;
    flex: 0 0 ${props => props.widthProp};
    display: flex;
    height: ${props => props.heightProp};
    box-sizing: border-box;
`

export const StyledInput = styled.input`
    display: none;
`

export const StyledLabel = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap: 8px;
    border-color: #009a92;
    border-radius:5px;
    padding: 12px 18px;
    cursor: 'pointer';
    background-color: ${props => props.theme.backgroundLabel};
    color: ${props => props.theme.activeBorderColor};
    font-weight: 500;
    border: 0;
    width: auto;
    text-align: center;
    white-space: nowrap;
`