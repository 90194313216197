import React from 'react'
import PropTypes from 'prop-types'

import Preloader from '../common/themed_preloader'
import FallbackThumbnail from '../helpers/fallback_thumbnail'

import styled from 'styled-components'
import { truncateMiddleText } from '../common/text_helpers'

const StyledAttachment = styled.div`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding: 2px 16px 11px 16px;
    background: ${props => props.isActive ? props.theme.color.gray10 : 'transparent'};
    border-radius: 2px;

    i {
        font-size: 12px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 5px;
    }
`

const StyledCaption = styled.div`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.smallRegular};
    margin: 0;
    max-width: 100px;
`

const StyledThumbnail = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    height: ${props => props.theme.thumbnail.height}px;
    width: ${props => props.theme.thumbnail.width}px;
    &:hover>.overlay{
        display:flex;
    }
`

export const StyledThumbnailImage = styled.img`
    max-height: ${props => props.theme.thumbnail.height - 2}px;
    max-width: ${props => props.theme.thumbnail.width - 2}px;
    width: auto;
    height: auto;
    border: 1px solid ${props => props.isActive ? props.theme.color.primary : props.theme.color.gray15};
    outline: 2px solid ${props => props.isActive ? props.theme.color.primary : 'transparent'};
`


const AttachmentThumbnailComponent = ({
    attachment,
    onAttachmentClick,
    showCaption = true,
    isActive = false,
    onDeleteFile
}) => {
    let truncateFileName = truncateMiddleText(attachment.fileName, 15)
    return (
        <StyledAttachment title={attachment.fileName} onClick={onAttachmentClick !== undefined ? () => { onAttachmentClick(attachment) } : undefined} isActive={isActive} className='jest-attachment-thumbnail'>
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <i title='delete document' onClick={onDeleteFile !== undefined ? () => { onDeleteFile(attachment) } : undefined} className="material-icons">clear</i>
            <StyledThumbnail>
                {
                    attachment.hasThumbnail && !attachment.thumbnailLaoadingFailed ? attachment.thumbnailIsLoading
                        ? <Preloader />
                        : <StyledThumbnailImage src={attachment.thumbnail} alt={attachment.fileName} isActive={isActive} />
                        : <FallbackThumbnail fileExtension={attachment.fileExtension} isActive={isActive} />
                }

            </StyledThumbnail>
            {showCaption && <StyledCaption>{truncateFileName}</StyledCaption>}
        </StyledAttachment>
    )

}

AttachmentThumbnailComponent.propTypes = {
    attachment: PropTypes.object,
    onAttachmentClick: PropTypes.func,
    onDeleteFile: PropTypes.func,
    showCaption: PropTypes.bool,
    isActive: PropTypes.bool
}

export default AttachmentThumbnailComponent