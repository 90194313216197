import React from 'react'
import PropTypes from 'prop-types'
import * as fileHelpers from '../../../common/file_helpers'
import * as Fragments from './fragments'
import Upload from '../images/upload.png'

const SimpleUploader = ({
    onFileAdded, 
    onFilesAdded,
    disabled = false, 
    width = '48px', 
    height = '48px', 
    name = '', 
    capture = false, 
    label = '', 
}) => {
    const accept = fileHelpers.getAccept()
    const handleChange = (e) => {
        const filesArray = Array.from(e.target.files)
        filesArray.length > 0 && fileHelpers.readFiles(filesArray, onFileAdded, onFilesAdded)
    }
    return <Fragments.StyledContainer disabled={disabled} widthProp={width} heightProp={height}>
        {
            capture 
                ? <Fragments.StyledInput onChange={(e)=>{handleChange(e)}} disabled={disabled} type='file' name={name} accept={accept} multiple capture/>
                : <Fragments.StyledInput onChange={(e)=>{handleChange(e)}} disabled={disabled} type='file' name={name} accept={accept} multiple/>
        }
        {
            label !== '' && <Fragments.StyledLabel className='buttonLabel'>
                <img 
                    src={Upload}
                    style={{
                        width: '16px',
                        height: '16px',
                    }}
                />
                {label}
                </Fragments.StyledLabel>
        }
    </Fragments.StyledContainer>
}

SimpleUploader.propTypes = {
    iconKey: PropTypes.string,
    onFileAdded: PropTypes.func.isRequired,
    onFilesAdded: PropTypes.func.isRequired,
    onFilesRejected: PropTypes.func,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string, 
    name: PropTypes.string,
    capture: PropTypes.bool,
    label: PropTypes.string,
}

export default SimpleUploader