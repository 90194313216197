import React from 'react'
import styled from 'styled-components'
import { useDynamicFormContext } from '../../contexts/use_dynamic_form_context'
import PropTypes from 'prop-types'

const MaxLengthStyled = styled.small`
    font-weight: 700;
    color: ${p => p.error ? p.theme.validationColor : '#4d4d4d'};
`

const MaxLengthMessage = props => {
    const { maxLength, name} = props
    const { watch } = useDynamicFormContext()
    const value = watch()[name] === '' || watch()[name] === undefined || watch()[name] === null || watch()[name] === [] ? 0 : watch()[name].length
    return maxLength > 0 ? <MaxLengthStyled error={value > maxLength}>
        {value}/{maxLength}
    </MaxLengthStyled> : null
}

export default MaxLengthMessage

MaxLengthMessage.propTypes = {
    maxLength: PropTypes.number,
    name: PropTypes.string
}