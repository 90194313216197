import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormElement } from './form_elements'
import { useDynamicFormContext } from './contexts/use_dynamic_form_context'

const StyledFormRoot = styled.div`
    width: inherit;
    height: auto;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    background-color: ${props => props.theme.formContainerBackground};
`

export const DynamicForm = ({
    formElements,
    onSubmit,
    onValidationError,
}) => {
    const { control, errors, getValues, handleSubmit, register, setForm, setStateForm, setValue, stateForm, watch, rsInsurancesOptions, gutachterOptions, gutachterGetData, setPostalCodeGutachter, gutachterIsLoading } = useDynamicFormContext()
    const onSubmitSuccess = () => {
        onSubmit(getValues())
    }
    return <StyledFormRoot>
        {
            formElements.map((f) => {
                return <FormElement
                    watch={watch}
                    handleSubmit={handleSubmit}
                    setForm={setForm}
                    error={f.type === 'file' ? errors['formelement_' + f.id] : errors[f.name] ? errors[f.name] : {}}
                    value={f.type === 'file' ? getValues()['formelement_' + f.id] : getValues()[f.name] ? getValues()[f.name] : ''}
                    register={register}
                    control={control}
                    setValue={setValue}
                    onSubmit={onSubmitSuccess}
                    onValidationError={onValidationError}
                    formElement={f}
                    key={f.id}
                    getValues={getValues}
                    setStateForm={setStateForm}
                    stateForm={stateForm}
                    rsInsurancesOptions={rsInsurancesOptions}
                    gutachterOptions={gutachterOptions}
                    gutachterIsLoading={gutachterIsLoading}
                    gutachterGetData={gutachterGetData}
                    setPostalCodeGutachter={setPostalCodeGutachter}
                />
            })
        }
    </StyledFormRoot>
}

DynamicForm.propTypes = {
    formElements: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValidationError: PropTypes.func,
    validationTexts: PropTypes.object,
    publicFileInfo: PropTypes.object
}
