import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDynamicFormContext } from '../contexts/use_dynamic_form_context'
import { formComponentsMapper } from './form_element_mapper'
import { getValidationMethod } from './_validation'
import { useQuery } from '../hooks/use_query'

export const FormElement = (props) => {
    // props inherit
    const {
        formElement,
        register,
        error,
        control,
        setForm,
        watch,
        value,
        handleSubmit,
        onSubmit,
        onValidationError,
        getValues,
        setStateForm,
        rsInsurancesOptions,
        gutachterOptions,
        gutachterGetData,
        setPostalCodeGutachter,
        gutachterIsLoading,
        isRepeater = false,
        iterElement= false,
    } = props
    const { requiredType, type } = formElement

    const { validationTexts, publicFileInfo, setValue, localizedTexts } = useDynamicFormContext()

    // mapper component - validation
    const MappedFormElement = formComponentsMapper(type)
    const validate = getValidationMethod(
        requiredType,
        type,
        formElement,
        validationTexts,
        publicFileInfo
    )

    const htmlId = formElement.name + '_' + formElement.id

    // prefilled value
    const query = useQuery()
    const preValue =
    query.getParsedParam(formElement.name) === ''
        ? ''
        : query.getStringParam(formElement.name)
    const preFill = preValue !== ''

    useEffect(() => {
        preFill && setValue(formElement.name, preValue)
    }, [preFill, setValue, formElement.name, preValue])
    return (
        <MappedFormElement
            htmlId={htmlId}
            register={register}
            error={error}
            validate={validate}
            control={control}
            onChange={setForm}
            setValue={setValue}
            watch={watch}
            value={value}
            formElement={formElement}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            onValidationError={onValidationError}
            getValues={getValues}
            publicFileInfo={publicFileInfo}
            setStateForm={setStateForm}
            localizedTexts={localizedTexts}
            validationTexts={validationTexts}
            rsInsurancesOptions={rsInsurancesOptions}
            gutachterOptions={gutachterOptions}
            gutachterIsLoading={gutachterIsLoading}
            gutachterGetData={gutachterGetData}
            setPostalCodeGutachter={setPostalCodeGutachter}
            isRepeater={isRepeater}
            iterElement={iterElement}
        />
    )
}

FormElement.propTypes = {
    formElement: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    error: PropTypes.object,
    control: PropTypes.object.isRequired,
    setForm: PropTypes.func,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    validationTexts: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
    ]),
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    onValidationError: PropTypes.func,
    publicFileInfo: PropTypes.object,
    getValues: PropTypes.func,
    setStateForm: PropTypes.func,
}
