import { useEffect } from 'react'
import useLocalStorage from '../../hooks/use_local_storage'
import React from 'react'
import { FORM_ELEMENT_TYPE } from '../../constants'
import { useDynamicFormContext } from '../../contexts/use_dynamic_form_context'

const FormSaveEffect = (props) => {
  const { children, workflowKey } = props
  const { watch, setValue } = useDynamicFormContext()
  const [formState, setFormState] = useLocalStorage(workflowKey)

  // Exclude form element keys
  const excludeKeys = [
    FORM_ELEMENT_TYPE.UPLOADER,
    FORM_ELEMENT_TYPE.DATE,
    FORM_ELEMENT_TYPE.SIGNATURE,
    FORM_ELEMENT_TYPE.GEOLOCATION,
    'formelement',
  ]

  // Load form if available in local storage
  useEffect(() => {
    if (formState) {
      Object.entries(JSON.parse(formState)).forEach(([key, value]) => {
        if (!excludeKeys.includes(key.toLowerCase().split('_')[0]))
          setValue(key, value)
      })
    }
  }, [])

  // Add form event listener to save forms in local storage
  useEffect(() => {
    const formSubscription = watch((formValues) => {
      let isEmpty = true

      Object.entries(formValues).map(([key, value]) => {
        if (!value) isEmpty = false
      })

      if (isEmpty) setFormState(null)
      else setFormState(JSON.stringify(formValues))
    })
    return () => formSubscription.unsubscribe()
  }, [watch])

  return <>{children}</>
}

export default FormSaveEffect
