import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FileIcon, defaultStyles } from 'react-file-icon'

const StyledFallbackThumbnailComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid 'transparent';
    height: ${props => props.divHeight ? props.divHeight : '70px'};
    width: ${props => props.divWidth ? props.divWidth : '70px'};
    & svg {
        width: 75%;
    }
`
const FallbackThumbnailComponent = ({ fileExtension, isActive = false }) => {
    return (
        <StyledFallbackThumbnailComponent isActive={isActive}><FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} /></StyledFallbackThumbnailComponent>
    )
}

FallbackThumbnailComponent.propTypes = {
    fileExtension: PropTypes.string.isRequired,
    isActive: PropTypes.bool
}

export default FallbackThumbnailComponent