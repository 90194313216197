
import styled from 'styled-components'

export const DropZone = styled.div`
    margin-top: 20px;
    border: 2px dashed #d9d9d9;
    border-radius: 5px;
    padding: 40px;
    width:100%;
`

export const DropZoneContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
`;