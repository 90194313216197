import React, { useState } from "react";
import * as Common from "../_common";
import * as Fragments from "./fragments";
import { FormElement } from "../form_element";
import { isMobile } from 'react-device-detect'

const Repeater = (props) => {
  const {
    htmlId,
    register,
    validate = null,
    error,
    hideValidationMessage = false,
    defaultValue = "",
    formElement,
    control,
    getValues,
  } = props;
  const maxSize = formElement.size;
  const label = formElement.formElements[0].text;
  
  let repeaterForms = null;
  
  if(formElement.formElements[0].formElements.length == 0){
    repeaterForms = formElement.formElements;
  }
  else{
    repeaterForms = formElement.formElements[0].formElements;
  }

  const [formSize, setFormSize] = useState(1);

  const removeRepeaterForm = (index) => {
    if (formSize > 1) {
      control.unregister(`isVisible_repeater_${index}_${formElement.name}`);
      repeaterForms.forEach((el) => {
        control.unregister(`repeater_${index}_${el.name}`);
      });
      setFormSize((prev) => prev - 1);
    }
  };

  const widthResponsive = isMobile ? '100%' : '50%'

  return (
    <Common.FormElementWrapper
      htmlId={htmlId}
      formElement={{ 
        ...formElement,
        text: formElement.formElements[0].type == 'customformtemplate' ? formElement.formElements[0].text : formElement.text
       }}
      hideValidationMessage={hideValidationMessage}
      error={error}
    >
        {/* <Common.Label htmlId={htmlId} label={label} /> */}
        {Array.from(Array(formSize).keys()).map((index) => (
            <Fragments.RepeaterWrapper>
            {repeaterForms.map((element) => {
              register(`isVisible_repeater_${index}_${formElement.name}`, {
                value: true,
              });
              return (
                <FormElement
                  {...props}
                  isRepeater = {true}
                  key={element.id}
                  value={getValues()[`repeater_${index}_formelement_${element.id}`]}
                  formElement={{
                    ...element,
                    name: `repeater_${index}_${element.name}`,
                    value: `repeater_${index}_${element.name}`,
                    uploaderKey: `repeater_${index}_formelement_${element.id}`,
                  }}
                  iterElement={true}
                />
              );
            })}
            {formSize > 1 && formSize === index + 1 && (
              <Fragments.RemoveButtonDiv>
                <Fragments.RemoveButton
                  width = {widthResponsive}
                  onClick={() => removeRepeaterForm(index)}
                >
                </Fragments.RemoveButton>
              </Fragments.RemoveButtonDiv>
            )}
            </Fragments.RepeaterWrapper>
        ))}
        {formSize < maxSize && (
          <Fragments.AddButtonDiv>
            <Fragments.AddButton
              width = {widthResponsive}
              onClick={() => setFormSize((prev) => prev + 1)}
            >
              Add {label}
            </Fragments.AddButton>
          </Fragments.AddButtonDiv>
        )}
    </Common.FormElementWrapper>
  );
};

export default Repeater;
