import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ErrorBox = styled.div`
    height: 16px !important;
`

export const ErrorLabel = styled.span`
    font-size: 10px;
    margin-top: 8px;
    font-weight: 700;
    color: ${props => props.theme.validationColor};
`

export const ValidationErrorMessage = ({ error = null, hideValidationMessage = false }) => {
    const hasError = error !== undefined && error !== null && error.message !== undefined
    return <ErrorBox className='ec-dynamic-form-error-message-container'>
        {
            hasError && !hideValidationMessage && <ErrorLabel className='ec-dynamic-form-error-message'>{error.message}</ErrorLabel>
        }
    </ErrorBox>
}

ValidationErrorMessage.propTypes = {
    error: PropTypes.object,
    errorMessageOverride: PropTypes.string,
    hideValidationMessage: PropTypes.bool
}
