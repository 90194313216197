export const handleApiResponse = (response) => {
    return response.text().then(text => {

        let data

        try{
            data = JSON.parse(text)
        }catch (e){
            data = text
        } 
        
        if(!response.ok){
            return Promise.reject({
                response, 
                data
            })
        }

        return data
    })
}