export const truncateEndText = (text, maxCount) => {
    text = text.length > maxCount ? text.substring(0, maxCount) : text
    text = text.length === maxCount ? text.substring(0, Math.min(text.length, text.lastIndexOf(' '))) + '...' : text

    return text
}

export const getInitials = (firstName, lastName) => {
    return firstName !== ''
        && firstName !== undefined
        && firstName !== null
        ? `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`
        : lastName.charAt(0).toUpperCase()
}

export const truncateMiddleText = (fullStr, strLen, separator) => {
    if (fullStr.length <= strLen) return fullStr

    separator = separator || '...'

    var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
}